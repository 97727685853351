<div class="spinner" *ngIf="!show">
  <div style="position: fixed; top: 0;left: 110px;">
    <div class="app-loading">
      <div class="logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>
</div>

<div>
  <div class="index-nav">
    <div
      *ngIf="!isExpanded"
      class="index-icon"
      (click)="playButton(isExpanded)"
      onClick="document.getElementById('mymarquee').stop();"
    >
      <mat-icon>pause</mat-icon>
    </div>
    <div
      *ngIf="isExpanded"
      class="index-icon"
      (click)="playButton(isExpanded)"
      onClick="document.getElementById('mymarquee').start();"
    >
      <mat-icon>play_arrow</mat-icon>
    </div>
    <marquee
      *ngIf="isMobile"
      behavior="scroll"
      direction="left"
      onmouseover="this.stop();"
      onmouseout="this.start();"
      id="mymarquee"
      scrolldelay="190"
    >
      <app-capital-markets-section></app-capital-markets-section>
    </marquee>

    <marquee
      *ngIf="!isMobile"
      behavior="scroll"
      direction="left"
      onmouseover="this.stop();"
      onmouseout="this.start();"
      id="mymarquee"
      scrolldelay="90"
    >
      <app-capital-markets-section></app-capital-markets-section>
    </marquee>
  </div>
  <div class="mat-elevation-z8 container-sections" style="border-radius: 8px;">
    <div style="text-align: center;">
      <div>
        <label
          (click)="goHome()"
          *ngIf="currentCode == 'br'"
          class="font-title current"
          for=""
          style="margin-right: 10px;"
          >REsource BR</label
        >
        <label
          (click)="goHome()"
          *ngIf="currentCode == 'mx'"
          class="font-title current"
          for=""
          style="margin-right: 10px;"
          >REsource MX</label
        >
        <label
          (click)="goHome(true)"
          *ngIf="currentCode == 'latam'"
          class="font-title current"
          for=""
          style="margin-right: 10px;"
          >REsource LATAM</label
        >
        <!-- <label (click)="goHome()" for="" class="font-title re">{{
          "news.re" | translate
        }}</label>
        <label (click)="goHome()" for="" class="font-title source">{{
          "news.source" | translate
        }}</label> -->
      </div>
      <div>
        <label
          class="tagUpper"
          for=""
          [ngClass]="color == '1' ? 'tag-property' : 'tag-state'"
          *ngIf="tagIdFilter != null"
        >
          {{ tagName.toUpperCase() }}</label
        >
      </div>
    </div>
    <app-loading-spinner *ngIf="loadingNews"></app-loading-spinner>
    <section *ngIf="alreadyLoaded" id="sectionContainer" class="news-container">
      <div class="first-section" *ngIf="news">
        <hr class="hr-sections" />
        <div
          class="mat-elevation-z8"
          style="border-radius: 8px; cursor: pointer;"
        >
          <div *ngIf="news.content[0].headerImage && !isMobile">
            <div
              *ngIf="news.content[0].contentTags"
              class="mb-3 tags-img"
              style="padding-right: 10px;"
            >
              <span
                class="tag-state tagonestate"
                (click)="
                  goNewsTags(
                    news.content[0].contentTags.stateTags[0],
                    2,
                    news.content[0].contentTags.regionOrigin
                  )
                "
              >
                {{ getStateTagName(news.content[0].contentTags) }}</span
              >
              <span
                class="tag-property"
                (click)="
                  goNewsTags(news.content[0].contentTags.propertyTypeTags[0], 1)
                "
              >
                {{
                  news.content[0].contentTags.propertyTypeTags[0].name
                    | langSelector
                }}</span
              >
            </div>
            <img
              id="AA"
              (click)="openNews(news.content[0].id, news.content[0])"
              class="w-100 img-topic aspect-4-3"
              [alt]="getAltTxt(news.content[0].headerImageCaption)"
              [title]="getAltTxt(news.content[0].headerImageCaption)"
              [src]="
                headerImageNews(news.content[0].headerImage.fullPath, true)
              "
            />
            <div
              *ngIf="
                news.content[0].subscriberOnly || news.content[0].sponsored
              "
              class="onlySubscriber"
            >
              <span
                *ngIf="news.content[0].sponsored"
                style="color: #00334D;padding: 10px;margin-right: 5px;"
                class="tag-state"
              >
                {{ "news.sponsored" | translate }}</span
              >
              <span
                *ngIf="news.content[0].subscriberOnly"
                style="color: #00334D;padding: 10px;"
                class="tag-state"
              >
                {{ "news.subscriberExclusive" | translate }}</span
              >
            </div>
            <!--   <figcaption class="ml-2" style="font-size: 14px;text-align: center;">
              {{ news.content[0].headerImageCaption | langSelector }}
            </figcaption> -->
          </div>

          <div class="mb-4 mt-3 ml-2 pb-2" *ngIf="!isMobile">
            <h2
              class="title-feature"
              (click)="openNews(news.content[0].id, news.content[0])"
            >
              {{ news.content[0].headline | langSelector }}
            </h2>
          </div>
        </div>

        <div
          *ngFor="
            let news2 of news.content.slice(
              isMobile ? 0 : 1,
              news.content.length
            )
          "
          class="news-card mat-elevation-z8 mb-3 most-read-card"
          style="border-radius: 8px; "
        >
          <div *ngIf="news2.headerImage">
            <img
              style="cursor: pointer;"
              (click)="openNews(news2.id, news2)"
              class="w-100 img-card aspect-4-3"
              [alt]="getAltTxt(news2.headerImageCaption)"
              [title]="getAltTxt(news2.headerImageCaption)"
              [src]="headerImageNews(news2.headerImage.fullPath)"
            />
            <div
              *ngIf="news2.subscriberOnly || news2.sponsored"
              class="onlySubscriber"
            >
              <span *ngIf="news2.sponsored" class="tag-state sponsored-falg">
                {{ "news.sponsored" | translate }}</span
              >
              <span
                *ngIf="news2.subscriberOnly"
                style="color: #00334D;padding: 10px;"
                class="tag-state"
              >
                {{ "news.subscriberExclusive" | translate }}</span
              >
            </div>
          </div>
          <div class="card">
            <div
              *ngIf="news2.contentTags"
              class="tags-card card-header border-none"
              style="cursor: pointer;"
            >
              <span
                class="tag-property"
                (click)="goNewsTags(news2.contentTags.propertyTypeTags[0], 1)"
              >
                {{
                  news2.contentTags.propertyTypeTags[0].name | langSelector
                }}</span
              >
              <span
                class="tag-state tagsecondstate"
                (click)="
                  goNewsTags(
                    news2.contentTags.stateTags[0],
                    2,
                    news2.contentTags.regionOrigin
                  )
                "
              >
                {{ getStateTagName(news2.contentTags) }}</span
              >
            </div>
            <h2
              (click)="openNews(news2.id, news2)"
              class="card-body border-none"
              style="font-weight: 700;font-size: 22px;cursor: pointer;color: #515050;"
            >
              {{ news2.headline | langSelector }}
            </h2>
            <div
              (click)="openNews(news2.id, news2)"
              class="sponsored card-footer border-none"
              style="cursor: pointer;"
            >
              <span *ngIf="getLanguage() === 'en'">
                {{ formatDate(news2.publishDate, "MM/dd/yyyy") }}
              </span>
              <span *ngIf="getLanguage() === 'es'">
                {{ formatDate(news2.publishDate, "dd/MM/yyyy") }}
              </span>
              <span *ngIf="getLanguage() === 'pt'">
                {{ formatDate(news2.publishDate, "dd/MM/yyyy") }}
              </span>
              <span *ngIf="news2.sponsored" style="text-align:center">
                {{ "news.sponsored" | translate }}
              </span>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
          <button
            mat-flat-button
            style="border: 1px solid #00334d !important;"
            *ngIf="total >= pageSize"
            (click)="seeMore()"
            class="title-card"
          >
            {{ "news.loadMore" | translate }}
          </button>
        </div>
      </div>
      <div class="second-section">
        <hr class="hr-sections" />
        <div class="search">
          <input
            [(ngModel)]="keyword"
            class="form-control"
            type="text"
            [placeholder]="'news.search' | translate"
          />
          <button
            mat-flat-button
            color="accent"
            (click)="openNewsSearch()"
            class="btn-send uppercase"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>

        <div
          style="background: #00334D;color: white;border-radius: 6px 6px 0px 0px;text-align: center;"
        >
          <label class="mt-2 ml-3" style="text-transform: uppercase;">{{
            "news.joinMailing" | translate
          }}</label>
        </div>
        <div class="send-mailing">
          <input
            type="text"
            [(ngModel)]="email"
            class="form-control"
            name=""
            id=""
            [placeholder]="'news.email' | translate"
          />
          <button
            mat-flat-button
            [disabled]="validEmail()"
            color="accent"
            (click)="sendEmail()"
            class="btn-send uppercase w-100 mt-2"
          >
            {{ "submit" | translate }}
          </button>
        </div>
        <div
          style="background: #00334D;color: white;border-radius: 6px 6px 0px 0px;text-align: center;"
        >
          <label class="mt-2 ml-3" for="label mailing">
            {{ "news.mostRead" | translate }}</label
          >
        </div>
        <div
          *ngFor="let mostNews of mostRead"
          class="p-2 most-read"
          (click)="openNews(mostNews.id, mostNews)"
          style="cursor: pointer;border-bottom: 1px solid gainsboro;"
        >
          <h5 style="font-weight: 700;color: #515050;">
            {{ validateTitle(mostNews.headline | langSelector) }}
          </h5>
          <span class="date-read" *ngIf="getLanguage() === 'en'">
            {{ formatDate(mostNews.publishDate, "MM/dd/yyyy") }}
          </span>
          <span class="date-read" *ngIf="getLanguage() === 'es'">
            {{ formatDate(mostNews.publishDate, "dd/MM/yyyy") }}
          </span>
          <span class="date-read" *ngIf="getLanguage() === 'pt'">
            {{ formatDate(mostNews.publishDate, "dd/MM/yyyy") }}
          </span>
        </div>
        <div *ngIf="false">
          <img
            [alt]="siilaAcademyBannerSEO.title"
            [title]="siilaAcademyBannerSEO.title"
            class="w-100"
            [src]="siilaAcademyBannerSEO.src"
            (click)="openAcademy()"
            style="cursor: pointer;"
          />
        </div>
        <div class="mt-3">
          <img
            [alt]="marketAnalyticsSEO.title"
            [title]="marketAnalyticsSEO.title"
            class="w-100"
            [src]="marketAnalyticsSEO.src"
            (click)="openMultifamilyBanner()"
            style="cursor: pointer;"
          />
        </div>
        <div class="mt-3 spotTransformingSEO">
          <img
            [alt]="spotTransformingSEO.alt"
            [title]="spotTransformingSEO.title"
            class="w-100"
            [src]="spotTransformingSEO.src"
            (click)="openSpot()"
            style="cursor: pointer;"
          />
        </div>

        <div class="mt-3">
          <h4 class="title-card">{{ topics[0]?.name | langSelector }}</h4>
          <hr class="hr-sections" />
          <div
            *ngFor="let topic of topics[0]?.newsList.slice(0, 2)"
            class="mat-elevation-z8"
            (click)="openNews(topic.id, topic)"
            style="border-radius: 8px;cursor: pointer;"
          >
            <img
              id="BB"
              class="w-100 img-topic aspect-14-9"
              [alt]="getAltTxt(topic.headerImageCaption)"
              [title]="getAltTxt(topic.headerImageCaption)"
              [src]="headerImageNews(topic.headerImage.fullPath)"
            />
            <div class="m-2 mt-3 pb-2">
              <span style="font-weight: 700; color: #515050;">{{
                topic.headline | langSelector
              }}</span>
              <!--  <br />
              <span class="date">{{ topic.createdDate | date: "longDate" }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <section *ngIf="alreadyLoaded" class="news-container" style="display: block;">
    <app-industry resource="true"></app-industry>
    <app-exclusive-access></app-exclusive-access>
    <app-btn-up></app-btn-up>
  </section>
</div>
<div class="install">
  <div class="install-flex" *ngIf="isMobile && !isStandAlone() && showInstall">
    <img src="assets/img/REsource-icon-rounded-border-no-line-2.png" />
    <span (click)="installApp()">{{ "navLinks.installApp" | translate }}</span>
    <button class="close" (click)="closeInstall()" mat-flat-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
