<section class="academy-container">
  <div class="border-card-academy mat-elevation-z15">
    <div class="academy-info-card">
      <h1
        class="title-gnrl"
        translate="academy.{{ currentCode }}.academy.title"
      ></h1>
      <h2
        class="academy-sub-title"
        [innerHTML]="
          'academy.' + currentCode + '.academy.sub-title' | translate
        "
      ></h2>
      <h3
        class="academy-description-head"
        translate="academy.{{ currentCode }}.academy.description"
      ></h3>
      <div class="button-academy">
        <button
          style="width: 118px;"
          (click)="openContactDialog(action.learnMore)"
          mat-flat-button
          color="accent"
          class="btn-more-academy"
        >
          {{ "more" | translate }}
        </button>
        <!--   <button
          mat-flat-button
          color="accent"
          (click)="downloadFormIndex()"
          class="btn-demo uppercase"
        >
          {{ "down" | translate }}
        </button> -->
      </div>
    </div>
    <div class="academy-img-siila" style="width:681px">
      <img
        *ngIf="!handsetMode"
        [alt]="imgAcademyAlt"
        [title]="imgAcademyAlt"
        [src]="imgAcademy()"
      />
      <img
        *ngIf="handsetMode"
        [alt]="imgAcademyAlt"
        [title]="imgAcademyAlt"
        [src]="imgAcademyMobile()"
      />
    </div>
  </div>
  <div
    *ngIf="!isMobile; else mobile"
    class="border-academy-white mat-elevation-z15"
  >
    <div class="siila-academy-img">
      <img
        [alt]="classesAlt"
        [title]="classesAlt"
        src="../../../../../assets/img/academy-list/academy-classes-MX-BR- all languages.png"
      />
    </div>
    <div class="siila-academy-info">
      <h1
        class="title-gnrl siila-blue-color"
        translate="academy.{{ currentCode }}.academy.what-is.title"
      ></h1>
      <h2
        class="siila-desc siila-menu-color"
        translate="academy.{{ currentCode }}.academy.what-is.sub-title"
      ></h2>
      <h3
        class="academy-description siila-grey"
        translate="academy.{{ currentCode }}.academy.what-is.description"
      ></h3>
    </div>
  </div>
  <ng-template #mobile>
    <div class="siila-academy-info mat-elevation-z15">
      <h1
        class="title-gnrl siila-blue-color"
        translate="academy.{{ currentCode }}.academy.what-is.title"
      ></h1>
      <h2
        class="siila-desc siila-menu-color"
        translate="academy.{{ currentCode }}.academy.what-is.sub-title"
      ></h2>
      <div class="siila-academy-img">
        <img
          [alt]="classesAlt"
          [title]="classesAlt"
          class="siila-img"
          src="../../../../../assets/img/academy-list/academy-classes-MX-BR- all languages.png"
        />
      </div>
      <h3
        class="academy-description-head siila-grey"
        translate="academy.{{ currentCode }}.academy.what-is.description"
      ></h3>
    </div>
  </ng-template>
  <div class="border-white mat-elevation-z15">
    <div class="news-info">
      <h2
        class="siila-desc siila-menu-color"
        translate="academy.{{ currentCode }}.academy.courses.sub-title"
      ></h2>
    </div>
    <div class="card-container" *ngIf="currentCode != 'latam'">
      <div class="card-items mat-elevation-z8">
        <img
          *ngIf="currentCode == 'br'"
          class="img-new"
          [alt]="officebr"
          [title]="officebr"
          src="../../../../../assets/img/academy-list/ACADEMY-OFFICES-BR.jpg"
        />
        <img
          *ngIf="currentCode == 'mx'"
          class="img-new"
          [alt]="officemx"
          [title]="officemx"
          src="../../../../../assets/img/academy-list/ACADEMY-OFFICES-mx.jpg"
        />
        <div class="card-info-academy">
          <h1
            class="card-title siila-menu-color"
            translate="academy.{{
              currentCode
            }}.academy.courses.card-info.title"
          ></h1>
          <div class="innerText">
            <h2
              class="academy-description-head siila-grey"
              translate="academy.{{
                currentCode
              }}.academy.courses.card-info.description"
            ></h2>
          </div>

          <button
            (click)="openCouse()"
            mat-flat-button
            color="accent"
            class="btn-more-academy uppercase"
          >
            {{
              currentCode == "br"
                ? ("academy." + currentCode + ".academy.subscriptionsNow"
                  | translate)
                : ("more" | translate)
            }}
          </button>
        </div>
      </div>
      <div class="card-items mat-elevation-z8">
        <img
          *ngIf="currentCode == 'br'"
          class="img-new"
          [alt]="industrialbr"
          [title]="industrialbr"
          src="../../../../../assets/img/academy-list/ACADEMY-INDUSTRIAL-BR.jpg"
        />
        <img
          *ngIf="currentCode == 'mx'"
          class="img-new"
          [alt]="industrialmx"
          [title]="industrialmx"
          src="../../../../../assets/img/academy-list/ACADEMY-INDUSTRIAL-mx.jpg"
        />
        <div class="card-info-academy">
          <h1
            class="card-title siila-menu-color"
            translate="academy.{{
              currentCode
            }}.academy.courses.card-info.title2"
          ></h1>
          <div class="innerText">
            <h2
              class="academy-description-head siila-grey"
              translate="academy.{{
                currentCode
              }}.academy.courses.card-info.description2"
            ></h2>
          </div>

          <button
            (click)="openCouse()"
            mat-flat-button
            color="accent"
            class="btn-more-academy"
          >
            {{
              currentCode == "br"
                ? ("academy." + currentCode + ".academy.subscriptionsNow"
                  | translate)
                : ("more" | translate)
            }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-container-latam" *ngIf="currentCode == 'latam'">
      <button
        class="btn-slide"
        mat-basic-button
        #videoPrev
        aria-label="left arrow"
        (click)="changeSlideBack(slickCarousel)"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <ngx-slick-carousel
        class="carousel"
        #slickCarousel
        style="width: 92%;"
        [config]="slideConfig"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)"
      >
        <div ngxSlickItem *ngFor="let course of courseLatam" class="slide">
          <div class="card-items mat-elevation-z8" [style]="course.width">
            <img
              class="img-new"
              [src]="course.img"
              [alt]="course.alt"
              [title]="course.alt"
            />
            <div class="card-info-academy">
              <h1
                class="card-title siila-menu-color"
                [translate]="course.title"
              ></h1>
              <div class="innerText">
                <h2
                  class="academy-description-head siila-grey"
                  [translate]="course.description"
                ></h2>
              </div>

              <button
                (click)="openCouse(course.code)"
                mat-flat-button
                color="accent"
                class="btn-more-academy uppercase"
              >
                {{ "more" | translate }}
              </button>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
      <button
        mat-basic-button
        #videoNext
        aria-label="right arrow"
        (click)="changeSlideNext(slickCarousel)"
        class="btn-slide"
        style="right: 0;"
      >
        <mat-icon *ngIf="!disableNext">chevron_right</mat-icon>
      </button>
    </div>
  </div>
  <div class="border-about-card mat-elevation-z15">
    <div class="course-container">
      <h1
        style="white-space: nowrap;"
        class="siila-desc uppercase"
        translate="academy.{{ currentCode }}.academy.courses.about.title"
      ></h1>
      <div class="item-container">
        <div class="item-list" *ngFor="let item of aboutList">
          <img [src]="item.icon" />
          <span
            class="item-title"
            style="display: block;
          margin-left: 22px;
          margin-top: -18px;"
            [translate]="item.title"
          ></span>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isMobile; else contact"
    class="border-white mat-elevation-z15"
    style="padding: 40px;"
  >
    <div class="demo-card" style="margin-top: 0px !important;">
      <div class="img-contact">
        <img
          class="contact-img"
          [alt]="personmobile"
          [title]="personmobile"
          [src]="imgContactUs()"
        />
      </div>
      <div class="contact-info">
        <h1
          class="siila-desc siila-menu-color"
          translate="academy.{{ currentCode }}.academy.courses.contact.title"
        ></h1>
        <h2
          class="academy-description siila-grey"
          translate="academy.{{
            currentCode
          }}.academy.courses.contact.description"
        ></h2>
        <form [formGroup]="form" class="contact-request">
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{
              "fullName" | translate
            }}</mat-label>
            <input matInput formControlName="fullName" />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "address" | translate }}</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "message" | translate }}</mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>
          <div class="academy-btn-touch">
            <button
              (click)="submitMessage()"
              mat-flat-button
              color="accent"
              class="btn-touch uppercase"
              [disabled]="form.invalid"
            >
              {{ "touchs" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ng-template #contact>
    <div class="contact-container mat-elevation-z15">
      <div class="contact-info">
        <h1
          class="siila-desc siila-menu-color"
          translate="academy.{{ currentCode }}.academy.courses.contact.title"
        ></h1>
        <div class="img-contact">
          <img
            class="contact-img"
            [alt]="personmobile"
            [title]="personmobile"
            [src]="imgContactUs()"
          />
        </div>
        <h2
          class="academy-description siila-grey"
          translate="academy.{{
            currentCode
          }}.academy.courses.contact.description"
        ></h2>
        <div class="contact-request">
          <form [formGroup]="form">
            <mat-form-field class="info-size">
              <mat-label class="name-text">{{
                "fullName" | translate
              }}</mat-label>
              <input matInput formControlName="fullName" />
            </mat-form-field>
            <mat-form-field class="info-size">
              <mat-label class="name-text">{{
                "address" | translate
              }}</mat-label>
              <input matInput formControlName="email" />
            </mat-form-field>
            <mat-form-field class="info-size">
              <mat-label class="name-text">{{
                "message" | translate
              }}</mat-label>
              <input matInput formControlName="description" />
            </mat-form-field>
            <div class="academy-btn-touch">
              <button
                (click)="submitMessage()"
                mat-flat-button
                color="accent"
                class="btn-touch uppercase"
                [disabled]="form.invalid"
              >
                {{ "touchs" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  <app-methodology></app-methodology>
  <!--  <app-featured-resources></app-featured-resources> -->
  <app-exclusive-access></app-exclusive-access>
  <app-btn-up></app-btn-up>
</section>
