<div class="modal-header custom-modal-class-tenants">
  <h4 class="modal-title" style="font-weight: 600;">
    {{ data.title || ("tenants-property.confirmation" | translate) }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="cancel()"
  ></button>
</div>
<div class="modal-body">
  <p>{{ data.message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    {{ data.cancelLabel || ("tenants-property.cancel" | translate) }}
  </button>
  <button type="button" class="btn btn-primary" (click)="confirm()">
    {{ data.confirmLabel || ("tenants-property.confirm" | translate) }}
  </button>
</div>
