<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-3 f-left mt-5">
      {{
        platformId == "mx" && property?.buildingType.id == 4001
          ? ("detail.industrialPark" | translate)
          : (property?.buildingType?.name | langSelector)
      }}
    </div>
    <div class="col-md-6 title-row f-left mt-1">
      <span class="title">
        {{ property?.title | langSelector }}
      </span>
      <br />
      <span>
        {{ property?.address | langSelector }},
        {{ property?.city?.name | langSelector }},
        {{ abbreviatedText(property?.city?.countryState?.name | langSelector) }}
      </span>
    </div>
    <div class="col-md-3 f-left mt-5" style="text-align: end;">
      {{
        platformId == "mx"
          ? property?.subMarket?.name
          : (property?.region?.name | langSelector)
      }}
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <div class="w-100">
      <app-show-images
        [photos]="photos"
        [latitude]="property?.latitude"
        [longitude]="property?.longitude"
        [buildingTypeId]="property?.buildingType.id"
        [complexInformation]="complexInformation"
        class="d-flex pl"
      ></app-show-images>
    </div>
    <div class="col-md-12 content-option mt-3">
      <div class="content-option-btn col-md-8">
        <!--   <div>
        <img
          style="width: 20px;
        margin-right: 5px;"
          src="assets/featuresIcons/analyze-2.png"
          alt=""
        /><span (click)="openBi('Building Information POC EN')">{{
          "detail.propertyAnalytics" | translate
        }}</span> 
      </div>-->
        <div
          *ngIf="!isMultifamily() && property?.buildingType.id != 4001"
          [ngClass]="{ 'disabled-option': !property?.transactionCount }"
        >
          <img
            style="width: 20px;
        margin-right: 5px;"
            src="assets/featuresIcons/analyze-2.png"
            alt=""
          /><span (click)="transactionsPage()">{{
            "detail.viewTransactions" | translate
          }}</span>
        </div>
        <div *ngIf="property?.buildingType.id != 9001">
          <img
            style="width: 20px;
        margin-right: 5px;"
            src="assets/featuresIcons/generate-reports.png"
            alt=""
          />
          <span (click)="downloadReport()">{{
            "detail.generateReport" | translate
          }}</span>
        </div>
        <div
          *ngIf="
            isAdmin && !isMultifamily() && property?.buildingType.id != 4001
          "
        >
          <img
            style="width: 24px;
        margin-right: 5px;"
            src="assets/featuresIcons/flash.png"
            alt=""
          />
          <span (click)="goToCreateTransactions()">{{
            "detail.createTransactions" | translate
          }}</span>
        </div>
        <!-- <div>
        <span (click)="spot()">{{ "detail.spot" | translate }}</span>
      </div> -->
        <div (click)="isAdmin ? edit() : suggestEdit()">
          <mat-icon style="margin-right: 6px;">edit</mat-icon>
          <span>{{
            isAdmin
              ? ("detail.edit" | translate)
              : ("detail.suggestEdit" | translate)
          }}</span>
        </div>
        <div *ngIf="isMobile">
          <span (click)="openPhotos(1)">{{ "detail.photos" | translate }}</span>
        </div>
      </div>

      <div class="content-option-btn col-md-4">
        <div
          style="border: none;
          border-right: 1px solid gray;
          border-radius: inherit;margin-left: -40px;"
        ></div>
        <div *ngIf="registry.length > 0">
          <span (click)="openPhotos(2)">{{
            "detail.matricula" | translate
          }}</span>
        </div>
        <div *ngIf="plan.length > 0">
          <span (click)="openPhotos(3)">{{ "detail.plans" | translate }}</span>
        </div>
        <div *ngIf="documents.length > 0">
          <span (click)="openPhotos(4)">{{
            "detail.otherDocs" | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-2">
      <div style="border-bottom: 1px solid #00334d;margin: 22px;"></div>
    </div>
    <div class="col-md-12 mt-5 content-columns">
      <div class="col-md-8">
        <div class="col-md-12 p-r p-html" *ngIf="property?.notes">
          <div class="col-md-12 key-facts ">
            <span>{{
              property.buildingType?.id == 4001
                ? ("detail.parkDescription" | translate)
                : ("detail.propertyDescription" | translate)
            }}</span>
          </div>

          <div
            class="mt-2"
            style="margin: 0;text-align: justify;white-space: pre-line;"
          >
            <!-- <span class="mt-2">{{ property?.notes | langSelector }}</span> -->
            <span class="mt-2" [innerHTML]="property.notes"></span>
          </div>
        </div>
        <div
          class="col-md-12 p-r dneighborDescDebug"
          *ngIf="neighborhoodDescription"
        >
          <div
            *ngIf="neighborhoodDescription"
            class="col-md-12 key-facts dneightDescTitle"
          >
            <span>{{ "detail.neighborhoodDescription" | translate }}</span>
          </div>

          <div
            class="mt-2 pneighborDescDebug"
            *ngIf="neighborhoodDescription"
            style="margin: 0;text-align: justify;"
          >
            <span class="mt-2">{{
              property?.neighborhoodDescription | langSelector
            }}</span>
          </div>
        </div>
        <app-property-features-br
          *ngIf="platformId == 'br'"
          [property]="property"
          [isMultifamily]="isMultifamily()"
          [customPropertyFeatures]="customPropertyFeatures"
        ></app-property-features-br>

        <app-property-features-mx
          *ngIf="platformId == 'mx'"
          [property]="property"
          [isMultifamily]="isMultifamily()"
          [customPropertyFeatures]="customPropertyFeatures"
        ></app-property-features-mx>

        <div class=" key-columns">
          <div class="col-md-6" [ngClass]="{ 'p-0': isMobile }">
            <div class="col-md-12 key-facts mt-2">
              <span>{{ "detail.keyFactsProperties" | translate }}</span>
            </div>
            <div
              *ngIf="isMultifamily()"
              id="key-facts-multicolumns"
              class="col-md-12 border-table p-0"
            >
              <div
                *ngFor="let keyFact of keyFactsMultifamilyOpts"
                [ngClass]="{ 'display-none': !keyFact?.enabled }"
                class="key-facts-multicolumns"
              >
                <div *ngIf="keyFact.enabled" class="column label-color">
                  {{ keyFact.displayName }}
                </div>
                <div class="column key-facts-value label-color2">
                  {{ keyFact.value }}
                </div>
              </div>
            </div>
            <div
              *ngIf="!isMultifamily()"
              class="d-flex key-facts-columns border-table"
            >
              <div
                class="column label-color"
                *ngIf="
                  platformId == 'mx'
                    ? platformId == 'mx' &&
                      property.buildingType?.id != 4001 &&
                      property?.buildingType.id != 3001 &&
                      property?.buildingType.id != 5001 &&
                      property?.classType?.name
                    : property?.classType?.name
                "
              >
                {{ "detail.class" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  platformId == 'mx'
                    ? platformId == 'mx' &&
                      property.buildingType?.id != 4001 &&
                      property?.buildingType.id != 3001 &&
                      property?.buildingType.id != 5001 &&
                      property?.classType?.name
                    : property?.classType?.name
                "
              >
                {{ property?.classType?.name }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.city?.countryState?.name"
              >
                {{ "detail.state" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.city?.countryState?.name"
              >
                {{ property?.city?.countryState?.name | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  platformId == 'mx' &&
                  property?.city?.name &&
                  property.buildingType?.id != 4001 &&
                  property.buildingType?.id != 1001
                "
              >
                {{ "detail.municipality" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  platformId == 'mx' &&
                  property?.city?.name &&
                  property.buildingType?.id != 4001 &&
                  property.buildingType?.id != 1001
                "
              >
                {{ property?.city?.name | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="platformId != 'mx' && property?.postalCode"
              >
                {{ "detail.postalCode" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId != 'mx' && property?.postalCode"
              >
                {{ property?.postalCode }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.region?.name || property?.subMarket?.name"
              >
                {{
                  platformId == "br"
                    ? ("detail.region" | translate)
                    : ("detail.subMarket" | translate)
                }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.region?.name || property?.subMarket?.name"
              >
                {{
                  platformId == "br"
                    ? (property?.region?.name | langSelector)
                    : property?.subMarket.name
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="currentFundsBuilding.length > 0"
              >
                {{ "detail.fibra" | translate }}
              </div>
              <div
                class="column label-color2 align-dev-own"
                style="text-align: end;"
                *ngIf="currentFundsBuilding.length > 0"
              >
                <div
                  *ngFor="let fund of currentFundsBuilding; let i = index"
                  (click)="navigateToFund(fund.fund.id)"
                >
                  <span style="color: #007bff !important;font-weight: 600;"
                    >{{ fund.fund.name | langSelector }}
                  </span>
                  <span
                    *ngIf="fund.ownershipPercentage"
                    style="color: #007bff !important;font-weight: 600;"
                    >({{ fund.ownershipPercentage | localNumber }}%)</span
                  >
                </div>
              </div>
              <div
                class="column label-color"
                *ngIf="platformId != 'mx' && property?.subMarket?.market.name"
              >
                {{ "detail.market" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId != 'mx' && property?.subMarket?.market.name"
              >
                {{ property?.subMarket?.market.name | langSelector }}
              </div>
              <div *ngIf="property?.latitude" class="column label-color">
                {{ "detail.latLong" | translate }}
              </div>
              <div *ngIf="property?.latitude" class="column label-color2">
                {{ property?.latitude }}/{{ property?.longitude }}
              </div>
              <!-- <div
                class="column label-color"
                *ngIf="
                  platformId == 'br' &&
                  property?.fund &&
                  property?.fiiOwnershipPercentage
                "
              >
                {{ "detail.fibra" | translate }}
              </div>
              <div
                *ngIf="
                  platformId == 'br' &&
                  property?.fund &&
                  property?.fiiOwnershipPercentage
                "
                (click)="navigateToFund(property?.fund?.id)"
                class="column label-color2"
              >
                <span class="fii"
                  >{{ property?.fund?.name | langSelector }}
                </span>
                <span class="fii"
                  >({{
                    property?.fiiOwnershipPercentage | localNumber
                  }}
                  %)</span
                >
              </div> -->
              <div
                *ngIf="
                  platformId == 'mx'
                    ? property?.buildingSubType &&
                      platformId == 'mx' &&
                      property.buildingType?.id != 1001
                    : property?.buildingSubType
                "
                class="column label-color"
              >
                {{ "detail.properySubtype" | translate }}
              </div>
              <div
                *ngIf="
                  platformId == 'mx'
                    ? property?.buildingSubType &&
                      platformId == 'mx' &&
                      property.buildingType?.id != 1001
                    : property?.buildingSubType
                "
                class="column label-color2"
              >
                {{ property?.buildingSubType?.name | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  platformId == 'mx'
                    ? property?.industrialStatus?.name &&
                      property.buildingType?.id != 4001
                    : property?.industrialStatus?.name
                "
              >
                {{ "detail.status" | translate }}
              </div>

              <div
                class="column label-color2"
                *ngIf="
                  platformId == 'mx'
                    ? property?.industrialStatus?.name &&
                      property.buildingType?.id != 4001
                    : property?.industrialStatus?.name
                "
              >
                {{ property?.industrialStatus?.name | langSelector }}
              </div>

              <div
                *ngIf="platformId != 'mx' && property?.tenancy?.name"
                class="column label-color "
              >
                {{ "detail.multiTenant" | translate }}
              </div>
              <div
                *ngIf="platformId != 'mx' && property?.tenancy?.name"
                class="column label-color2"
              >
                {{ property?.tenancy.name | langSelector }}
              </div>
              <div
                *ngIf="
                  platformId == 'mx'
                    ? property?.deliveredDate &&
                      property.buildingType?.id != 4001
                    : property?.deliveredDate
                "
                class="column label-color"
              >
                {{
                  isFutureDate(property?.deliveredDate) != ""
                    ? ("detail.planneddeliveryDate" | translate)
                    : ("detail.deliveryDate" | translate)
                }}
              </div>
              <div
                *ngIf="
                  platformId == 'mx'
                    ? property?.deliveredDate &&
                      property.buildingType?.id != 4001
                    : property?.deliveredDate
                "
                class="column label-color2"
              >
                {{
                  platformId == "mx"
                    ? formatDate(property?.deliveredDate)
                    : browserLang == "pt"
                    ? (property?.deliveredDate | date: "dd/MM/yyyy")
                    : (property?.deliveredDate | date: "MM/dd/yyyy")
                }}
              </div>
              <div
                *ngIf="property?.planneddeliveryDate"
                class="column label-color"
              >
                {{ "detail.planneddeliveryDate" | translate }}
              </div>
              <div
                *ngIf="property?.planneddeliveryDate"
                class="column label-color2"
              >
                {{
                  browserLang == "pt"
                    ? (property?.planneddeliveryDate | date: "dd/MM/yyyy")
                    : (property?.planneddeliveryDate | date: "MM/dd/yyyy")
                }}
              </div>
              <div
                *ngIf="property?.lastExpansionDeliveryDate"
                class="column label-color"
              >
                {{
                  platformId == "mx" && property.buildingType?.id != 1001
                    ? ("detail.renovationDate" | translate)
                    : ("detail.ExpansionDeliveryDate" | translate)
                }}
              </div>
              <div
                *ngIf="property?.lastExpansionDeliveryDate"
                class="column label-color2"
              >
                {{
                  platformId == "mx"
                    ? formatDate(property?.lastExpansionDeliveryDate)
                    : browserLang == "pt"
                    ? (property?.lastExpansionDeliveryDate | date: "dd/MM/yyyy")
                    : (property?.lastExpansionDeliveryDate | date: "MM/dd/yyyy")
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.newConstructionType"
              >
                {{ "detail.constructionType" | translate }}
              </div>

              <div
                class="column label-color2"
                *ngIf="property?.newConstructionType"
              >
                {{ property?.newConstructionType.displayName | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.industrialComplex"
              >
                {{ "detail.insdustrialComplex" | translate }}
              </div>

              <div
                class="column label-color2"
                *ngIf="property?.industrialComplex"
              >
                <a
                  [href]="'/property/' + property?.industrialComplex.id"
                  target="_blank"
                  >{{ property?.industrialComplex.title | langSelector }}</a
                >
              </div>
              <div
                class="column label-color"
                *ngIf="
                  platformId == 'mx' &&
                  !property?.industrialComplex &&
                  property?.buildingSubType &&
                  property.buildingType?.id == 1001
                "
              >
                {{ "detail.properySubtype" | translate }}
              </div>

              <div
                class="column label-color2"
                *ngIf="
                  platformId == 'mx' &&
                  !property?.industrialComplex &&
                  property?.buildingSubType &&
                  property.buildingType?.id == 1001
                "
              >
                {{ "detail.industrialBuilding" | translate }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.status && isAdmin"
              >
                {{ "detail.recordStatus" | translate }}
              </div>

              <div
                class="column label-color2"
                *ngIf="property?.status && isAdmin"
              >
                {{
                  platformId == "mx" && browserLang == "es"
                    ? translateStatus(property?.status)
                    : property?.status
                }}
              </div>

              <div class="column label-color" *ngIf="property?.retrofitDate">
                {{ "detail.retrofitDate" | translate }}
              </div>

              <div class="column label-color2" *ngIf="property?.retrofitDate">
                {{ property?.retrofitDate }}
              </div>
              <div class="column label-color" *ngIf="property?.neighborhood">
                {{ "detail.neighborhood" | translate }}
              </div>

              <div class="column label-color2" *ngIf="property?.neighborhood">
                {{ property?.neighborhood }}
              </div>
            </div>
            <div
              *ngIf="
                property?.buildingType.id != 9001 &&
                property.buildingType.id != 3001 &&
                validateMarketRent.length > 0
              "
              class="col-md-12 mt-2"
              style="padding: 0px;"
            >
              <div class="col-md-12 key-facts">
                <span>{{
                  platformId == "mx"
                    ? ("detail.marketMaintenanceFee" | translate)
                    : ("detail.marketRentTax" | translate)
                }}</span>
              </div>
              <div class=" table-vacant" style="overflow: hidden;">
                <table class="table">
                  <tbody class=" border-table">
                    <tr>
                      <td class="label-color">
                        {{ "detail.rent" | translate }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="validateMarketRent.length > 0"
                      >
                        {{ validateMarketRent[0].marketRent | localNumber }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="
                          marketRentList[marketRentList.length - 1]
                            ?.averageMonthlyAskingRentPerArea
                        "
                      >
                        {{
                          marketRentList[marketRentList.length - 1]
                            .averageMonthlyAskingRentPerArea | localNumber
                        }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        property?.bomaOperatingExpenses ||
                        property?.monthlyManagementFees
                      "
                    >
                      <td class="label-color">
                        {{ "detail.operatingExpenses" | translate }}
                        {{ !isBomaMarket ? "" : "(BOMA)" }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="isBomaMarket"
                      >
                        {{ property?.bomaOperatingExpenses | localNumber }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="!isBomaMarket"
                      >
                        {{ property?.monthlyManagementFees | localNumber }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        property?.bomaPropertyTax ||
                        property?.monthlyPropertyTax
                      "
                    >
                      <td class="label-color">
                        {{ "detail.propertyTax" | translate }}
                        {{ !isBomaMarket ? "" : "(BOMA)" }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="isBomaMarket"
                      >
                        {{ property?.bomaPropertyTax | localNumber }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="!isBomaMarket"
                      >
                        {{ property?.monthlyPropertyTax | localNumber }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        platformId == 'mx' && property?.monthlyManagementFeesUSD
                      "
                    >
                      <td class="label-color">
                        {{ "detail.maintenanceFees" | translate }}
                      </td>
                      <td class="label-color2" style="text-align: end;">
                        {{ property?.monthlyManagementFeesUSD | localNumber }}
                      </td>
                    </tr>
                    <tr>
                      <td class="total-row" style="font-weight: 600;">
                        {{
                          platformId == "mx" ? "Total USD/m²" : "Total R$/m²"
                        }}
                      </td>
                      <td
                        *ngIf="isBomaMarket"
                        style="text-align: end;font-weight: 600;"
                        class="total-row"
                      >
                        {{ sumBomaRent() | localNumber }}
                      </td>
                      <td
                        *ngIf="!isBomaMarket && validateMarketRent.length > 0"
                        class="total-row"
                        style="text-align: end;font-weight: 600;"
                      >
                        {{ sumprivateRent() | localNumber }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              id="manager"
              class="propertyManagerDeb"
              [ngStyle]="{
                display:
                  listManager.length > 2 && property.buildingType?.id != 4001
                    ? 'block'
                    : 'none'
              }"
            >
              <div class="col-md-12 key-facts mt-2">
                <span>{{
                  "detail.propertyManagerInformation" | translate
                }}</span>
              </div>
              <div class="d-flex key-facts-columns border-table">
                <div
                  class="column label-color"
                  *ngIf="property?.propertyManagementCompany"
                >
                  {{ "detail.propertyManager" | translate }}
                </div>
                <div
                  class="column label-color2 align-dev-own"
                  *ngIf="property?.propertyManagementCompany"
                  style="text-align: right;"
                >
                  <span
                    *ngIf="
                      platformId == 'br' ||
                      property?.propertyManagementCompany != 'Self Managed'
                    "
                    (click)="
                      openCompanySearch(property?.propertyManagementCompany)
                    "
                  >
                    {{ property?.propertyManagementCompany }}
                  </span>
                  <span
                    *ngIf="
                      platformId == 'mx' &&
                      property?.propertyManagementCompany == 'Self Managed'
                    "
                    style="color: #212529;"
                  >
                    {{ property?.propertyManagementCompany }}
                  </span>
                </div>
                <div class="column label-color" *ngIf="property?.managerName">
                  {{ "detail.name" | translate }}
                </div>
                <div class="column label-color2" *ngIf="property?.managerName">
                  {{ property?.managerName | translate }}
                </div>
                <div
                  class="column label-color"
                  *ngIf="property?.managerTelephone"
                >
                  {{ "detail.telephoneNumber" | translate }}
                </div>
                <div
                  class="column label-color2"
                  style="color: #2c94d3"
                  *ngIf="property?.managerTelephone"
                >
                  <img
                    class="show-data-img"
                    src="assets/featuresIcons/call_icon.png"
                    alt=""
                  /><span
                    *ngIf="!showPhone"
                    (click)="showPhone = !showPhone"
                    style="cursor: pointer;"
                    >{{ "detail.showPhone" | translate }}</span
                  >
                  <span *ngIf="showPhone">
                    {{ property?.managerTelephone }}</span
                  >
                </div>
                <div class="column label-color" *ngIf="property?.managerEmail">
                  {{ "detail.email" | translate }}
                </div>
                <div
                  class="column label-color2"
                  style="    width: 50%;
                word-wrap: break-word;color: #2c94d3"
                  *ngIf="property?.managerEmail"
                >
                  <span
                    *ngIf="!showEmail"
                    (click)="showEmail = !showEmail"
                    style="cursor: pointer;"
                    ><img
                      class="show-data-img"
                      src="assets/featuresIcons/mail_icon.png"
                      alt=""
                    />{{ "detail.showEmail" | translate }}</span
                  >
                  <span *ngIf="showEmail"
                    ><img
                      class="show-data-img"
                      src="assets/featuresIcons/mail_icon.png"
                      alt=""
                    />{{ property?.managerEmail }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 p-r">
            <div class="col-md-12 key-facts mt-2">
              <span>{{ "detail.areasMeasurements" | translate }}</span>
            </div>
            <app-areas-measurements-br
              *ngIf="platformId == 'br'"
              [property]="property"
              [isMultifamily]="isMultifamily()"
              [areasMeasurementsOpts]="areasMeasurementsOpts"
              [hiddeMoreFeatures]="hiddeMoreFeatures"
            ></app-areas-measurements-br>
            <app-areas-measurements-mx
              *ngIf="platformId == 'mx'"
              [property]="property"
              [isMultifamily]="isMultifamily()"
              [areasMeasurementsOpts]="areasMeasurementsOpts"
              [complexInformation]="complexInformation"
            ></app-areas-measurements-mx>
            <div
              class="col-md-12 vacancy-button mt-3"
              *ngIf="
                vacancySpot.length > 0 &&
                vacantAreaList.length > 0 &&
                platformId == 'br'
              "
            >
              <button
                class="form-control"
                (click)="openVacancy(vacancySpot[0])"
                mat-flat-button
                color="accent"
                style="font-weight: bold;"
              >
                {{ "detail.viewVacancy" | translate }}
              </button>
            </div>

            <div
              class="col-md-12 mt-3"
              *ngIf="
                vacantAreaList.length > 0 &&
                platformId == 'mx' &&
                (property?.buildingType.id == 1001 ||
                  property?.buildingType.id == 2001)
              "
              style="padding: 0px;"
            >
              <div class="col-md-12 key-facts">
                <span>{{ "detail.vacancyTitle" | translate }}</span>
              </div>
              <div
                class="table-unitmix"
                id="vacancyTable"
                [ngClass]="{ 'scroll-vacancy': vacantAreaList.length > 7 }"
              >
                <table class="table">
                  <thead class="head-vacant title-row unitmixheaders">
                    <tr>
                      <th class="table-column">
                        {{
                          property?.buildingType.id == 2001
                            ? ("detail.vacancyFloor" | translate)
                            : ("detail.vacancyModule" | translate)
                        }}
                      </th>
                      <th
                        class="table-column"
                        *ngIf="property?.buildingType.id == 2001"
                      >
                        {{ "detail.unit" | translate }}
                      </th>
                      <th class="table-column">
                        {{ "detail.vacancyArea" | translate }}
                      </th>
                      <th class="table-column">
                        {{ "detail.vacancyAskingRentM2" | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="border-table title-row unitmixtablerows">
                    <tr *ngFor="let vacant of vacantAreaList">
                      <td class="table-column">
                        {{
                          property?.buildingType.id == 2001
                            ? vacant.floor
                            : vacant.module
                        }}
                      </td>
                      <td
                        class="table-column"
                        *ngIf="property?.buildingType.id == 2001"
                      >
                        {{ vacant.suiteNumber }}
                      </td>
                      <td class="table-column">
                        {{ vacant.area | localNumber: "1.2-2" }}
                      </td>
                      <td class="table-column">
                        <span
                          *ngIf="
                            buildingOccupancies &&
                            buildingOccupancies.averageMonthlyAskingRentPerAreaUSD >=
                              0
                          "
                          >{{
                            buildingOccupancies.averageMonthlyAskingRentPerAreaUSD
                              | localNumber: "1.2-2"
                          }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <div
                class="col-md-12  mt-3 p-0"
                *ngIf="
                  (vacancySpot.length > 0 &&
                    vacantAreaList.length > 0 &&
                    platformId == 'mx' &&
                    (property?.buildingType.id == 1001 ||
                      property?.buildingType.id == 2001)) ||
                  property?.id == 64760
                "
              >
                <button
                  class="form-control"
                  (click)="openVacancy(vacancySpot[0])"
                  mat-flat-button
                  color="accent"
                  style="font-weight: bold"
                >
                  {{ "detail.viewVacancy" | translate }}
                </button>
              </div>
            </div>

            <div
              class="col-md-12 vacancy-button mt-3"
              *ngIf="
                historicOccupancies.length > 0 &&
                (property?.buildingType.id == 3001 ||
                  property?.buildingType.id == 9001)
              "
            >
              <button
                class="form-control"
                (click)="openChartPopup(chartModal)"
                mat-flat-button
                color="accent"
                style="font-weight: bold;"
              >
                {{ "detail.occupancyHistory" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div
          *ngIf="property.buildingType.id == 4001"
          class="col-md-12 mt-2 industrialPark"
          style="padding-right: 0px;"
        >
          <div class="col-md-12 key-facts mt-2">
            <span>{{ "detail.industrialBuildingTitle" | translate }}</span>
          </div>
          <table class="table">
            <thead class="text-center">
              <tr>
                <th class="default-column">{{ "detail.pin" | translate }}</th>
                <th class="property-column">
                  {{ "detail.property" | translate }}
                </th>
                <th class="default-column">
                  {{ "detail.grossLeaseableArea" | translate }}
                </th>
                <th
                  [ngClass]="{ 'display-none': countPlannedDate === 0 }"
                  class="default-column"
                >
                  {{ "detail.plannedDelivery" | translate }}
                </th>
                <th class="default-column">
                  {{ "detail.deliveryDate" | translate }}
                </th>
                <th class="default-column">
                  {{ "detail.propertyStatus" | translate }}
                </th>
                <th class="view-transactions-column">
                  {{ "detail.viewTransactions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr
                *ngFor="
                  let complex of complexInformation.warehouses;
                  let i = index
                "
              >
                <td class="default-column">{{ i + 1 }}</td>
                <td class="property-column">
                  <a
                    [href]="'property/' + complex.id + '/lang/' + browserLang"
                    target="_blank"
                    >{{ complex.title | langSelector }}</a
                  >
                </td>
                <td class="default-column">
                  {{ complex.rentableArea | localNumber: "1.0-0" }}
                </td>
                <td
                  [ngClass]="{ 'display-none': countPlannedDate === 0 }"
                  class="default-column"
                >
                  {{ isFutureDate(complex.deliveredDate) }}
                </td>
                <td class="default-column">
                  {{ isPastDate(complex.deliveredDate) }}
                </td>
                <td class="default-column">
                  {{ complex.industrialStatus?.name | langSelector }}
                </td>
                <td class="view-transactions-column">
                  <span (click)="transactionsPage(complex.id)" class="link">{{
                    complex.transactionCount ? complex.transactionCount : ""
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="!isMobile" class="col-md-4 ">
        <aside>
          <div *ngIf="listingCards.length > 0">
            <ngx-slick-carousel #slickListing>
              <div ngxSlickItem *ngFor="let listing of listingCards">
                <featured-listing-card
                  class="slide"
                  [hideLogo]="true"
                  [listing]="listing"
                  [latamCountry]="null"
                ></featured-listing-card>
              </div>
            </ngx-slick-carousel>
            <button
              *ngIf="listingCards.length > 1"
              (click)="slickPrev(slickListing)"
              mat-basic-button
              #videoPrev
              aria-label="left arrow"
              class="news-leftLs btnCard chevron_left-listing"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
              *ngIf="listingCards.length > 1"
              (click)="slickNext(slickListing)"
              mat-basic-button
              #videoNext
              aria-label="right arrow"
              class="news-rightLs btnCard chevron_right-listing"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div
            id="findBannerImage"
            (click)="openBannerImage()"
            *ngIf="listingCards.length == 0"
          >
            <img class="banner-img" [src]="findBannerImage()" alt="" />
          </div>
          <div *ngIf="articles && articles.content.length > 0">
            <ngx-slick-carousel #slickResource>
              <div
                ngxSlickItem
                *ngFor="let article of articles.content"
                class="slide"
              >
                <div style="text-align: center;">
                  <span class="title" style="text-decoration: underline;">{{
                    "detail.featuredResource" | translate
                  }}</span>
                </div>
                <div
                  class="mat-elevation-z8 mt-2 resource"
                  style="cursor: pointer;"
                  (click)="openNews(article.id, article)"
                >
                  <img
                    class="w-100 img-topic aspect-4-3"
                    alt="test"
                    title="test"
                    [src]="
                      article?.headerImage?.fullPath
                        ? headerImageNews(article?.headerImage?.fullPath)
                        : ''
                    "
                    default="assets/img/defaultImage.png"
                  />
                  <div class="m-2 mt-3 pb-2">
                    <span style="font-weight: 700; color: #515050;"></span>
                    {{ article.headline | langSelector }}
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
            <button
              *ngIf="articles.content.length > 1"
              [ngClass]="{
                'chevron_left-resource-banner': listingCards.length == 0
              }"
              (click)="slickPrev(slickResource)"
              mat-basic-button
              #videoPrev
              aria-label="left arrow"
              class="news-leftLs btnCard chevron_left-resource"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
              *ngIf="articles.content.length > 1"
              [ngClass]="{
                'chevron_right-resource-banner': listingCards.length == 0
              }"
              (click)="slickNext(slickResource)"
              mat-basic-button
              #videoNext
              aria-label="right arrow"
              class="news-rightLs btnCard chevron_right-resource"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div
            id="findSpotResourceBanner"
            (click)="openSpotResourceBannerLink()"
            *ngIf="articles && articles.content.length == 0"
          >
            <img class="banner-img" [src]="findSpotResourceBanner()" alt="" />
          </div>
        </aside>
      </div>
    </div>
    <div class="col-md-12 mt-4 ">
      <div
        class="col-md-12 p-0"
        *ngIf="marketRentList.length > 0 && validateMarketRent.length > 0"
      >
        <div class="col-md-12" *ngIf="!isMobile">
          <span class="title col-md-12 ">{{
            "detail.historicVacancyMarketRents" | translate
          }}</span>
        </div>
        <div *ngIf="!isMobile" class="col-md-12 title-bar mt-3">
          <mat-button-toggle-group
            class="toggle-group"
            appearance="legacy"
            name="fontStyle"
            aria-label="Font Style"
          >
            <mat-button-toggle
              [ngClass]="{ 'active-toggle': typeBar === 1 }"
              class="toogle-btn"
              (click)="setHistoricBar(1); setOccupancyGraph()"
              value="1"
            >
              {{ "detail.currentProperty" | translate }}</mat-button-toggle
            >
            <mat-button-toggle
              [ngClass]="{ 'active-toggle': typeBar === 2 }"
              class="toogle-btn"
              (click)="setHistoricBar(2); setOccupancyGraph()"
              value="2"
            >
              {{
                platformId == "mx"
                  ? property?.subMarket.name
                  : (property?.region?.name | langSelector)
              }}
              {{ property?.classType.name }}</mat-button-toggle
            >
            <mat-button-toggle
              [ngClass]="{ 'active-toggle': typeBar === 3 }"
              class="toogle-btn"
              (click)="setHistoricBar(3)"
              value="3"
            >
              {{ "detail.combined" | translate }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
        <div class="col-md-12" id="chart">
          <div class="col-md-12 mt-3 apex-width" *ngIf="!isMobile">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [yaxis]="chartOptions.yaxis"
              [labels]="chartOptions.labels"
              [title]="chartOptions.title"
              [theme]="chartOptions.theme"
              [stroke]="chartOptions.stroke"
              [colors]="chartOptions.colors"
            ></apx-chart>
          </div>
        </div>
        <div
          class="col-md-12"
          style="display: flex; flex-direction: column; align-items: flex-end;"
          *ngIf="
            occupancies.length > 0 &&
            (property?.buildingType.id == 1001 ||
              property?.buildingType.id == 2001)
          "
        >
          <button
            class="form-control btn-historical"
            (click)="openChartPopup(chartModal)"
            mat-flat-button
            color="accent"
            *ngIf="
              historicOccupancies.length > 0 &&
              (property?.buildingType.id == 1001 ||
                property?.buildingType.id == 2001)
            "
          >
            {{ "detail.occupancyRentHistory" | translate }}
          </button>
          <button
            class="form-control"
            (click)="openTenantsOwners()"
            mat-flat-button
            color="accent"
            *ngIf="
              rentRolls.length > 0 &&
              !(
                owners.length > 0 ||
                developers.length > 0 ||
                industryPercentagesList.industryPercentages.length > 0
              )
            "
            style="font-weight: bold;width: 350px;"
          >
            {{ "detail.viewTenantsOwners" | translate }}
          </button>
        </div>
      </div>
      <div>
        <div class="col-md-12">
          <div
            *ngIf="industryPercentagesList.industryPercentages.length > 0"
            class="col-md-12 mt-3"
          >
            <span class="title col-md-12 ">{{
              "detail.tenantsIndustry" | translate
            }}</span>
          </div>

          <div
            *ngIf="industryPercentagesList.industryPercentages.length > 0"
            class="col-md-12 pie-content"
            id="chart"
          >
            <div class="col-md-6 mt-3 pie" *ngIf="!isMobile">
              <apx-chart
                [series]="chartOptionsPie.series"
                [chart]="chartOptionsPie.chart"
                [labels]="chartOptionsPie.labels"
                [responsive]="chartOptionsPie.responsive"
                [colors]="chartOptionsPie.colors"
              ></apx-chart>
            </div>
            <button
              *ngIf="tenantAreas.length > 0"
              mat-flat-button
              color="accent"
              style="    position: absolute;
          margin: 16px;
          left: 36%;
          bottom: 1;"
              (click)="resetTenantIndustry()"
            >
              {{ "detail.all" | translate }}
            </button>
            <div
              class="col-md-6 mt-2"
              *ngIf="industryPercentagesList.tenantAreas.length > 0"
              style="padding: 0px;"
            >
              <div class="col-md-12 key-facts">
                <span>{{ "detail.topTenants" | translate }}</span>
              </div>
              <div class="table-tenant table-container">
                <table class="table">
                  <thead class="head-vacant">
                    <tr>
                      <th>{{ "detail.rank" | translate }}</th>
                      <th>
                        {{ "detail.tenant" | translate }}
                      </th>
                      <th>{{ "detail.areaOccupied" | translate }}</th>
                      <th>{{ "detail.industry" | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let tenant of tenantAreas.length > 0
                          ? tenantAreas
                          : industryPercentagesList.tenantAreas;
                        let i = index
                      "
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ tenant.tenantName }}</td>
                      <td>{{ tenant.area | localNumber }}</td>
                      <td>{{ tenant.categoryName | langSelector }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="pointOfInterest.length > 0">
          <div class="col-md-12">
            <div class="col-md-12 key-facts">
              <span>{{ "detail.pointsOfInterest" | translate }}</span>
            </div>
            <div class="border-table points-table">
              <table class="table" *ngFor="let point of pointOfInterest">
                <thead class="head-vacant">
                  <tr>
                    <th>{{ point[0].type.displayName | langSelector }}</th>
                    <th>{{ "detail.driveTime" | translate }}</th>
                    <th>{{ "detail.distance" | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let rowPoint of point; let i = index">
                    <td>{{ rowPoint.name }}</td>
                    <td>{{ rowPoint.driveTime }} min</td>
                    <td>{{ rowPoint.distance }} km</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="unitsMix && unitsMix.length > 0"
        class="col-md-12 unitmixtable"
      >
        <div class="col-md-12 key-facts">
          <span>{{ "detail.unitMix" | translate }}</span>
        </div>
        <div class="table-unitmix">
          <table class="table">
            <thead class="head-vacant title-row unitmixheaders">
              <tr>
                <th>{{ "detail.floorPlans" | translate }}</th>
                <th style="max-width: 145px;">
                  {{ "detail.numberOfUnits" | translate }}
                </th>
                <th style="min-width: 95px;">
                  {{ "detail.area" | translate }} (m²)
                </th>
                <th *ngIf="unitMixColVisibility.get('cqAvgMonthlyRent')">
                  {{ "detail.monthlyRent" | translate }}
                </th>
                <th *ngIf="unitMixColVisibility.get('monthlyrentsm')">
                  {{ "detail.monthlyRentm2" | translate }}
                </th>
                <th
                  style="width: 171px;"
                  *ngIf="unitMixColVisibility.get('cqAvgMonthlyRentFurnished')"
                >
                  {{ "detail.monthlyRentf" | translate }}
                </th>
                <th
                  style="width: 196px;"
                  *ngIf="unitMixColVisibility.get('monthlyrentsmf')"
                >
                  {{ "detail.monthlyRentm2f" | translate }}
                </th>
                <th *ngIf="unitMixColVisibility.get('detailsName')">
                  {{ "detail.details" | translate }}
                </th>
              </tr>
            </thead>
            <tbody class="border-table title-row unitmixtablerows">
              <tr
                *ngFor="
                  let um of unitsMix && unitsMix.length > 0 ? unitsMix : [];
                  let i = index
                "
              >
                <td
                  style="text-align: right;"
                  *ngIf="unitMixColVisibility.get('floorPlan')"
                >
                  {{ um.floorPlan }}
                </td>
                <td *ngIf="unitMixColVisibility.get('numberOfUnits')">
                  {{ um.numberOfUnits }}
                </td>
                <td *ngIf="unitMixColVisibility.get('avgArea')">
                  {{ um.avgArea }}
                </td>
                <td *ngIf="unitMixColVisibility.get('cqAvgMonthlyRent')">
                  {{ um.cqAvgMonthlyRent }}
                </td>
                <td *ngIf="unitMixColVisibility.get('cqAvgMonthlyRent')">
                  {{ um.monthlyrentsm }}
                </td>
                <td
                  *ngIf="unitMixColVisibility.get('cqAvgMonthlyRentFurnished')"
                >
                  {{ um.cqAvgMonthlyRentFurnished }}
                </td>
                <td
                  *ngIf="unitMixColVisibility.get('cqAvgMonthlyRentFurnished')"
                >
                  {{ um.monthlyrentsmf }}
                </td>
                <td *ngIf="unitMixColVisibility.get('detailsName')">
                  {{ um.detailsName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="col-md-12 d-flex mt-3"
        style="padding: 0px;"
        *ngIf="owners.length > 0 || developers.length > 0"
      >
        <div class="col-md-12">
          <div class="col-md-12 key-facts mt-2">
            <span>{{ "detail.ownersDevelopers" | translate }}</span>
          </div>
          <div
            class="d-flex border-table"
            [ngClass]="{ 'd-block': isMobile }"
            style="    margin-top: -1px;"
          >
            <div class="column label-color owner-dev-columns">
              {{ "detail.owners" | translate }}
            </div>
            <div
              class="column label-color2 align-dev-own"
              style="border: 1px solid #e0e2e7;"
            >
              <span
                class="d-flex"
                (click)="openCompanySearch(owner)"
                *ngFor="let owner of owners.slice(0, 10); let i = index"
              >
                <p>{{ i + 1 }}.</p>
                <p>{{ owner }}</p>
              </span>
            </div>
            <div class="column label-color owner-dev-columns">
              {{ "detail.developer" | translate }}
            </div>
            <div
              class="column label-color2 align-dev-own"
              style="border: 1px solid #e0e2e7;"
            >
              <span
                class="d-flex"
                (click)="openCompanySearch(developer)"
                *ngFor="let developer of developers.slice(0, 10); let i = index"
              >
                <p>{{ i + 1 }}.</p>
                <p>{{ developer }}</p>
              </span>
            </div>
          </div>
        </div>

        <!--   <div class="col-md-6">
        <div class="col-md-12 key-facts mt-2">
          <span>{{ "detail.ownersDevelopers" | translate }}</span>
        </div>
        <div class="d-flex key-facts-columns border-table">
        
        </div>
      </div> -->
      </div>
      <div
        class="col-md-12 mt-3"
        *ngIf="
          (platformId == 'mx' && rentRolls.length > 0) || platformId == 'br'
        "
      >
        <div
          class="col-md-12"
          style="display: flex; justify-content: flex-end; padding: 0"
          *ngIf="
            (platformId == 'mx' && occupancies.length > 0) || platformId == 'br'
          "
        >
          <button
            class="form-control"
            (click)="openChartPopup(chartModal)"
            mat-flat-button
            color="accent"
            style="font-weight: bold; width: 350px;"
            *ngIf="
              !(marketRentList.length > 0 && validateMarketRent.length > 0) &&
              historicOccupancies.length > 0 &&
              (property?.buildingType.id == 1001 ||
                property?.buildingType.id == 2001)
            "
          >
            {{ "detail.occupancyRentHistory" | translate }}
          </button>
          <button
            class="form-control"
            (click)="openTenantsOwners()"
            mat-flat-button
            color="accent"
            style="font-weight: bold;width: 350px;"
            *ngIf="
              owners.length > 0 ||
              developers.length > 0 ||
              industryPercentagesList.industryPercentages.length > 0
            "
          >
            {{ "detail.viewTenantsOwners" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="isMobile" class="col-md-12 ">
      <aside>
        <div *ngIf="listingCards.length > 0">
          <ngx-slick-carousel #slickListing>
            <div ngxSlickItem *ngFor="let listing of listingCards">
              <featured-listing-card
                class="slide"
                [hideLogo]="true"
                [listing]="listing"
                [latamCountry]="null"
              ></featured-listing-card>
            </div>
          </ngx-slick-carousel>
          <button
            *ngIf="listingCards.length > 1"
            (click)="slickPrev(slickListing)"
            mat-basic-button
            #videoPrev
            aria-label="left arrow"
            class="news-leftLs btnCard chevron_left-listing"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            *ngIf="listingCards.length > 1"
            (click)="slickNext(slickListing)"
            mat-basic-button
            #videoNext
            aria-label="right arrow"
            class="news-rightLs btnCard chevron_right-listing"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <div
          id="findBannerImage"
          (click)="openBannerImage()"
          *ngIf="listingCards.length == 0"
        >
          <img class="banner-img" [src]="findBannerImage()" alt="" />
        </div>
        <div *ngIf="articles && articles.content.length > 0">
          <ngx-slick-carousel #slickResource>
            <div
              ngxSlickItem
              *ngFor="let article of articles.content"
              class="slide"
            >
              <div style="text-align: center;">
                <span class="title" style="text-decoration: underline;">{{
                  "detail.featuredResource" | translate
                }}</span>
              </div>
              <div
                class="mat-elevation-z8 mt-2 resource"
                style="cursor: pointer;"
                (click)="openNews(article.id, article)"
              >
                <img
                  class="w-100 img-topic aspect-4-3"
                  alt="test"
                  title="test"
                  [src]="
                    article?.headerImage?.fullPath
                      ? headerImageNews(article?.headerImage?.fullPath)
                      : ''
                  "
                  default="assets/img/defaultImage.png"
                />
                <div class="m-2 mt-3 pb-2">
                  <span style="font-weight: 700; color: #515050;"></span>
                  {{ article.headline | langSelector }}
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
          <button
            *ngIf="articles.content.length > 1"
            [ngClass]="{
              'chevron_left-resource-banner': listingCards.length == 0
            }"
            (click)="slickPrev(slickResource)"
            mat-basic-button
            #videoPrev
            aria-label="left arrow"
            class="news-leftLs btnCard chevron_left-resource"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            *ngIf="articles.content.length > 1"
            [ngClass]="{
              'chevron_right-resource-banner': listingCards.length == 0
            }"
            (click)="slickNext(slickResource)"
            mat-basic-button
            #videoNext
            aria-label="right arrow"
            class="news-rightLs btnCard chevron_right-resource"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <div
          id="findSpotResourceBanner"
          (click)="openSpotResourceBannerLink()"
          *ngIf="articles && articles.content.length == 0"
        >
          <img class="banner-img" [src]="findSpotResourceBanner()" alt="" />
        </div>
      </aside>
    </div>
    <div class="col-md-12 mt-5">
      <div class="col-md-12 content-title">
        <span class="title col-md-12 ">{{
          "detail.availabilityProperties" | translate
        }}</span>
        <br />
        <span
          class="col-md-12 "
          style="color: #a9a9a9;
        font-weight: 600;
        font-size: 16px;"
          >{{ "detail.propertiesSaleRent" | translate }}</span
        >
      </div>
      <div class="propComparabledbg">
        <featured-listings
          class="mt-2 propertiescomparableFtlistings"
          [listings]="propertiesComparable"
          [hideLogo]="true"
        >
        </featured-listings>
      </div>
    </div>

    <div class="col-md-12" *ngIf="buildings.length > 0">
      <div class="col-md-12 content-title">
        <span class="title col-md-12 ">{{
          "detail.comparableProperties" | translate
        }}</span>
      </div>
      <featured-listings-detail
        class="buildingftliddtaildb"
        [buildings]="buildings"
        [hideLogo]="true"
        [detail]="true"
      >
      </featured-listings-detail>
      <!--  <div
        class="col-md-12"
        style="text-align: center;
        margin-bottom: 60px;margin-top: -34px;"
      >
        <button mat-stroked-button color="primary">
          {{ "detail.seeMore" | translate }}
        </button>
      </div> -->
    </div>
    <div class="col-md-12 mt-2">
      <p
        translate="products.{{
          currentCode
        }}.products.features-card.marketAnalytics.findMore"
        class="find-more siila-grey-dark"
      ></p>
      <div class="market-analytics-card mt-5">
        <ng-container *ngFor="let card of marketCardList">
          <app-info-card
            (click)="openInfoCard(card)"
            [icon]="card.src"
            [iconAltTitle]="card.iconAltTitle"
            [title]="card.title | langSelector"
            [text]="card.text | langSelector"
            [actionTxt]="card.actionText | langSelector"
            [background]="card.background"
          ></app-info-card>
        </ng-container>
      </div>
      <!-- <div class="col-md-12">
        <span class="title col-md-12 ">{{
          "detail.learnMoreAboutProducts" | translate
        }}</span>
      </div>

      <div
        class="col-md-12 mt-3 content-columns"
        style="margin: 0 auto;
        width: 90%;"
      >
        <div class="col-md-4">
          <div
            class="mat-elevation-z8 mt-2"
            style="cursor: pointer;
                      margin: 0 auto;"
          >
            <img
              class="w-100 aspect-14-9"
              alt="test"
              title="test"
              src=""
              default="assets/img/sbi.jpg"
            />
            <div class="m-2 mt-3 pb-2 about-products">
              <div>
                <strong>{{ "detail.sbi" | translate }}</strong>
              </div>
              <div>
                <span>{{ "detail.siilaBrasilIndex" | translate }}</span>
              </div>
              <div>
                <button
                  routerLink="/products/102/siila-brasil-index"
                  mat-flat-button
                  color="accent"
                >
                  {{ "detail.learnMore" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="mat-elevation-z8 mt-2"
            style="cursor: pointer;
                      margin: 0 auto;"
          >
            <img
              class="w-100 aspect-14-9"
              alt="test"
              title="test"
              src=""
              default="assets/img/FII_Data.jpg"
            />
            <div class="m-2 mt-3 pb-2 about-products">
              <div>
                <strong>{{ "detail.fiiDataProfessional" | translate }}</strong>
              </div>
              <div>
                <span>{{ "detail.reitsAnalytics" | translate }}</span>
              </div>
              <div>
                <button
                  routerLink="/fii-data-pro"
                  mat-flat-button
                  color="accent"
                >
                  {{ "detail.getStarted" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div
            class="mat-elevation-z8 mt-2"
            style="cursor: pointer;
                      margin: 0 auto;"
          >
            <img
              class="w-100 aspect-14-9"
              alt="test"
              title="test"
              src=""
              default="assets/img/academy.jpg"
            />
            <div class="m-2 mt-3 pb-2 about-products">
              <div>
                <strong>{{ "detail.academy" | translate }}</strong>
              </div>
              <div>
                <span>{{ "detail.rigurousEngaging" | translate }}</span>
              </div>
              <div>
                <button routerLink="/academy" mat-flat-button color="accent">
                  {{ "detail.getStarted" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

<!-- <div
  class="modal fade"
  id="chartModal"
  #chartModal
  tabindex="-1"
  aria-labelledby="chartModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered"
    style="max-width: 350px;"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="chartModalLabel">
          {{ "detail.occupancyHistoryTitle" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-md-12 key-facts">
          <span>{{ "detail.occupancy" | translate }}</span>
        </div>
        <div class=" table-tenant">
          <table class="table">
            <thead class="head-vacant">
              <tr>
                <th>
                  {{ "detail.occDate" | translate }}
                </th>
                <th>{{ "detail.occupancy" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let occ of occupancies; let i = index">
                <td>
                  {{
                    browserLang == "pt"
                      ? (occ.dateSurveyed | date: "dd/MM/yyyy")
                      : (occ.dateSurveyed | date: "MM/dd/yyyy")
                  }}
                </td>
                <td>
                  <span *ngIf="occ.occupancy >= 0"
                    >{{ occ.occupancy | localNumber }} %</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div> -->

<!-- NgbModal Template -->
<ng-template #chartModal let-modal>
  <div class="custom-modal custom-modal-centered">
    <div class="modal-header">
      <h5 class="modal-title" *ngIf="property?.buildingType.id != 3001">
        {{ "detail.occupancyRentHistoryTitle" | translate }}
      </h5>
      <h5 class="modal-title" *ngIf="property?.buildingType.id == 3001">
        {{ "detail.occupancyHistoryTitle" | translate }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="table-tenant-popup">
        <table class="table">
          <thead
            class="head-vacant"
            style="text-align: center; background-color: #5a86a3;"
          >
            <tr>
              <th>{{ "detail.occDate" | translate }}</th>
              <th>{{ "detail.occupancy" | translate }}</th>
              <th
                *ngIf="
                  property?.buildingType.id != 3001 &&
                  property?.buildingType.id != 9001
                "
              >
                {{ "transaction.marketRent" | translate }}
              </th>
            </tr>
          </thead>
          <tbody style="text-align: center;">
            <tr *ngFor="let occ of historicOccupancies; let i = index">
              <td>
                {{ occ.dateSurveyed }}
              </td>
              <td>
                <span *ngIf="occ.occupancy >= 0"
                  >{{ occ.occupancy | localNumber }} %</span
                >
              </td>
              <td
                *ngIf="
                  property?.buildingType.id != 3001 &&
                  property?.buildingType.id != 9001
                "
              >
                <span *ngIf="occ.marketRent >= 0">{{
                  occ.marketRent | localNumber
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "detail.close" | translate }}
      </button>
    </div>
  </div>
</ng-template>
