<div class="border-section container-sections mt-3">
  <div class="card-press" title="Header Image for SiiLA Newsroom">
    <div class="card-content">
      <span class="title-gnrl">{{ "press.title" | translate }}</span>
      <br />
      <span class="title-press">{{ "press.text" | translate }}</span>
    </div>
  </div>
  <div class="press-banner">
    <span
      class="banner-title"
      innerHTML="{{ 'press.bannerTitle' | translate }}"
    ></span>
    <div class="iconlabel-container">
      <mat-icon>email</mat-icon>
      <a href="mailto:resource@siila.com" class="resource-email"
        >resource@siila.com</a
      >
    </div>
    <div class="iconlabel-container">
      <mat-icon> phone</mat-icon>
      <span>+55 11 99419-2463</span>
    </div>
  </div>
  <div class="search-filter">
    <div class="filter">
      <label class="mt-3" style="font-weight: 700;" for="">{{
        "press.keyword" | translate
      }}</label>
      <input
        [(ngModel)]="filters.keywordFilter"
        class="form-control"
        type="text"
        placeholder="{{ 'press.keywordPlaceHolder' | translate }}"
      />
    </div>
    <div class="filter" style="flex: 1 1 2%;">
      <label class="mt-3" style="font-weight: 700;" for="">{{
        "press.start" | translate
      }}</label>
      <input
        [(ngModel)]="filters.startDate"
        (ngModelChange)="filterData()"
        class="form-control"
        type="date"
      />
    </div>
    <div class="filter" style="flex: 1 1 2%;">
      <label class="mt-3" style="font-weight: 700;" for="">{{
        "press.end" | translate
      }}</label>
      <input
        [(ngModel)]="filters.endDate"
        (ngModelChange)="filterData()"
        class="form-control"
        type="date"
      />
    </div>
    <div class="search-button mt-4">
      <div>
        <button
          (click)="searchKeyword()"
          mat-flat-button
          color="accent"
          style="margin-top: 24px;"
          class="form-control"
          [disabled]="validateSearch()"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div>
        <button
          (click)="clearFilter()"
          mat-stroked-button
          color="primary"
          style="margin-top: 24px;font-weight: 600;"
          class="form-control"
        >
          {{ "press.clear" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div style="height: 58px;">
    <ngb-pagination
      style="float: right;"
      [collectionSize]="total"
      [(page)]="currentPage"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event)"
      [maxSize]="isMobile ? 3 : 5"
    ></ngb-pagination>
  </div>
  <div
    *ngIf="listPress.length == 0"
    style="text-align: center;
  font-weight: 600;font-size: 25px;"
  >
    <span>{{ "press.errorSearch" | translate }}</span>
  </div>
  <div *ngIf="listPress.length > 0">
    <section
      class="section border-section read-card"
      *ngFor="let press of listPress"
    >
      <div (click)="openPress(press)" style="cursor: pointer;">
        <div class="headline ml-20">
          <h3 class="title-feature">{{ press.headline }}</h3>
        </div>
        <div class="headline ml-20">
          <span
            style="    color: darkgray;
            font-weight: 600; font-size: 20px;"
            >{{ press.publishedDate }}</span
          >
        </div>
      </div>
      <div style="text-align: center;margin-top: 10px;">
        <div
          style="width: 146px;
        height: 95px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;"
        >
          <img
            style="max-width: 146px;
          max-height: 95px;"
            [src]="headerImageNews(press.pressSource.sourceImg.fullPath)"
            title="{{ press.pressSource.sourceName }}  Logo"
          />
        </div>

        <div class="d-flex card-pdf">
          <div class="pdf">
            <img
              [title]="press.pdfOriginalNameEn"
              (click)="downloadPdf(press, 'en')"
              src="assets/img/pdfP.png"
              alt=""
              style="width: 28px; cursor: pointer;"
            />
            <span>EN</span>
          </div>
          <div class="pdf">
            <img
              [title]="press.pdfOriginalNamePt"
              (click)="downloadPdf(press, 'pt')"
              src="assets/img/pdfP.png"
              alt=""
              style="width: 28px; cursor: pointer;"
            />
            <span>PT</span>
          </div>
          <div class="pdf">
            <img
              [title]="press.pdfOriginalNameEs"
              (click)="downloadPdf(press, 'es')"
              src="assets/img/pdfP.png"
              alt=""
              style="width: 28px; cursor: pointer;"
            />
            <span>ES</span>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div style="height: 20px;">
    <ngb-pagination
      style="float: right;"
      [collectionSize]="total"
      [(page)]="currentPage"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event)"
      [maxSize]="isMobile ? 3 : 5"
    ></ngb-pagination>
  </div>
</div>

<div class="news-container" style="display: block;">
  <app-industry resource="true"></app-industry>
</div>
