<ng-container *ngIf="addNoIndexNoFollowTag">
  <meta name="robots" content="noindex, nofollow" />
</ng-container>
<div *ngIf="isDataAvailable">
  <!-- coockies usage banner -->
  <div
    style="max-width: 1236px;margin: 0 auto;"
    app-app-top-banner
    [buttonText]="'global.acceptCookies.accept'"
    (onButtonClick)="acceptCookieUsage()"
    [visible]="!cookieAcceptanceAccepted"
    *ngIf="!cookieAcceptanceAccepted && !hideMenu"
  >
    <p class="no-margin">
      <span>{{
        "global.acceptCookies.message" | translate | translateStringCutter: 0
      }}</span>
      <a [routerLink]="['/privacy-policy']" class="siila-blue-color">{{
        "global.acceptCookies.message" | translate | translateStringCutter: 1
      }}</a>
      <span>{{
        "global.acceptCookies.message" | translate | translateStringCutter: 2
      }}</span>
    </p>
  </div>
  <!-- nav main top block -->
  <header class="app-toolbar" *ngIf="handsetMode && !hideMenu">
    <menu class="sticky-bar" (toggleSideMenu)="sidenav.toggle()"></menu>
  </header>
  <div class="app-toolbar" *ngIf="!handsetMode && !hideMenu">
    <menu class="sticky-bar" (toggleSideMenu)="sidenav.toggle()"></menu>
  </div>
  <!-- nav main top block -->
  <div class="app-content">
    <mat-sidenav-container>
      <mat-sidenav class="slide-nav-size" #sidenav mode="over">
        <mat-list>
          <span class="side-menu">
            <mat-icon>home</mat-icon>
            <div class="icon-flex">
              <a class="nav-item" routerLink="/" (click)="goTo($event, '/')">
                <span class="text-span" translate="navLinks.home"></span>
              </a>
              <!-- <mat-icon>arrow_drop_down</mat-icon> -->
            </div>
          </span>
          <div class="border-line-menu"></div>
          <span class="side-menu">
            <mat-icon>devices</mat-icon>
            <div mat-button [matMenuTriggerFor]="menu" class="icon-flex">
              <a class="nav-item">
                <span class="text-span" translate="navLinks.products"></span>
              </a>

              <mat-icon>arrow_drop_down</mat-icon>
              <mat-menu #menu="matMenu">
                <a class="btn-decoration" routerLink="/products">
                  <button
                    mat-menu-item
                    (click)="sidenav.toggle()"
                    class="mat-sub-menu"
                  >
                    <mat-icon class="icon-style">important_devices</mat-icon>
                    {{ "navLinks.allProducts" | translate }}
                  </button>
                </a>

                <a class="btn-decoration" routerLink="/marketanalytics">
                  <button
                    mat-menu-item
                    (click)="sidenav.toggle()"
                    class="mat-sub-menu"
                  >
                    <mat-icon class="icon-style">bar_chart</mat-icon>
                    MARKET ANALYTICS
                  </button>
                </a>
                <div *ngIf="currentCode != 'co'">
                  <a
                    *ngFor="let item of checkId"
                    [routerLink]="[
                      '/products',
                      item.id,
                      item.title | langSelector: true
                    ]"
                    class="btn-decoration"
                  >
                    <button
                      class="mat-sub-menu"
                      (click)="sidenav.toggle()"
                      mat-menu-item
                    >
                      <mat-icon class="icon-style">{{ item.icon }}</mat-icon>
                      {{ item.title | langSelector }}
                    </button>
                  </a>

                  <a class="btn-decoration" routerLink="/SPOT">
                    <button
                      mat-menu-item
                      (click)="sidenav.toggle()"
                      class="mat-sub-menu"
                    >
                      <mat-icon class="icon-style">location_pin</mat-icon>
                      SiiLA SPOT
                    </button>
                  </a>
                  <a class="btn-decoration" routerLink="/academy">
                    <button
                      mat-menu-item
                      (click)="sidenav.toggle()"
                      class="mat-sub-menu"
                    >
                      <mat-icon class="icon-style">school</mat-icon>
                      ACADEMY
                    </button>
                  </a>
                  <a
                    class="btn-decoration"
                    [routerLink]="
                      currentCode == 'mx' || currentCode == 'latam'
                        ? '/fibra-analytics'
                        : '/fii-data-pro'
                    "
                  >
                    <button
                      mat-menu-item
                      (click)="sidenav.toggle()"
                      class="mat-sub-menu"
                    >
                      <mat-icon class="icon-style">corporate_fare</mat-icon>
                      {{
                        (currentCode == "mx"
                          ? "navLinks.fiiDataMx"
                          : "navLinks.fiiData"
                        ) | translate
                      }}
                    </button>
                  </a>
                </div>
              </mat-menu>
            </div>
          </span>
          <div class="border-line-menu"></div>
          <span class="side-menu">
            <mat-icon>device_hub</mat-icon>
            <div mat-button [matMenuTriggerFor]="solution" class="icon-flex">
              <a class="nav-item">
                <span class="text-span" translate="navLinks.solutions"></span>
              </a>
              <mat-icon>arrow_drop_down</mat-icon>
              <mat-menu #solution="matMenu">
                <a
                  *ngFor="let link of solutions"
                  [routerLink]="[
                    '/solutions',
                    link.id,
                    link.solutionName | langSelector: true
                  ]"
                  class="btn-decoration"
                >
                  <button
                    mat-menu-item
                    (click)="sidenav.toggle()"
                    class="mat-sub-menu uppercase"
                  >
                    {{ link.shortName | langSelector }}
                  </button>
                </a>
              </mat-menu>
            </div>
          </span>
          <div class="border-line-menu"></div>
          <!--  <span class="side-menu">
            <mat-icon>insights</mat-icon>
            <div class="icon-flex">
              <a class="nav-item" (click)="goTo($event, '/blog')">
                <span class="text-span" translate="navLinks.blog"></span>
              </a>
            </div>
          </span> -->
          <span class="side-menu">
            <mat-icon>insights</mat-icon>
            <div mat-button [matMenuTriggerFor]="insights" class="icon-flex">
              <a class="nav-item">
                <span class="text-span">REsource</span>
              </a>
              <mat-icon>arrow_drop_down</mat-icon>
              <mat-menu #insights="matMenu">
                <a
                  *ngIf="currentCode != 'co'"
                  routerLink="/resource"
                  class="btn-decoration"
                >
                  <button
                    (click)="sidenav.toggle()"
                    mat-menu-item
                    class="mat-sub-menu"
                  >
                    <img
                      class="m-2"
                      width="30"
                      src="../../../../assets/icons/library_books_FILL0_wght400_GRAD0_opsz48.svg"
                      alt=""
                    />
                    <span style="text-transform: uppercase;">{{
                      "news.resource" | translate
                    }}</span>
                  </button>
                </a>
                <a
                  *ngIf="currentCode == 'co'"
                  (click)="openResource('mx')"
                  class="btn-decoration"
                >
                  <button mat-menu-item class="mat-sub-menu">
                    <img
                      class="m-2"
                      width="24"
                      src="../../../../assets/icons/library_books_FILL0_wght400_GRAD0_opsz48.svg"
                      alt=""
                    />
                    <span style="text-transform: uppercase;">{{
                      "news.resourceMx" | translate
                    }}</span>
                  </button>
                </a>
                <a
                  *ngIf="currentCode == 'co'"
                  (click)="openResource('br')"
                  class="btn-decoration"
                >
                  <button mat-menu-item class="mat-sub-menu">
                    <img
                      class="m-2"
                      width="24"
                      src="../../../../assets/icons/library_books_FILL0_wght400_GRAD0_opsz48.svg"
                      alt=""
                    />
                    <span style="text-transform: uppercase;">{{
                      "news.resourceBr" | translate
                    }}</span>
                  </button>
                </a>
                <a *ngIf="currentCode != 'co'" class="btn-decoration">
                  <button
                    (click)="openMedia()"
                    mat-menu-item
                    class="mat-sub-menu"
                  >
                    <img
                      class="m-2"
                      width="30"
                      src="../../../../assets/icons/play_circle_FILL0_wght400_GRAD0_opsz48.svg"
                      alt=""
                    /><span style="text-transform: uppercase;">{{
                      "news.media" | translate
                    }}</span>
                  </button>
                </a>
              </mat-menu>
            </div>
          </span>

          <div class="border-line-menu"></div>

          <span class="side-menu">
            <mat-icon>business_center</mat-icon>
            <div mat-button [matMenuTriggerFor]="companyopt" class="icon-flex">
              <a class="nav-item">
                <span class="text-span">{{
                  "navLinks.company" | translate
                }}</span>
              </a>
              <mat-icon>arrow_drop_down</mat-icon>
              <mat-menu #companyopt="matMenu">
                <a routerLink="/about" class="btn-decoration">
                  <button mat-menu-item class="mat-sub-menu">
                    {{ "navLinks.aboutUs" | translate }}
                  </button>
                </a>
                <a
                  routerLink="/about"
                  fragment="leadership"
                  class="btn-decoration"
                >
                  <button mat-menu-item class="mat-sub-menu">
                    {{ "navLinks.leadership" | translate }}
                  </button>
                </a>
                <a fragment="leadership" class="btn-decoration">
                  <button
                    mat-menu-item
                    class="mat-sub-menu"
                    (click)="openPress()"
                  >
                    {{ "navLinks.press" | translate }}
                  </button>
                </a>
              </mat-menu>
            </div>
          </span>

          <!--<div class="border-line-menu"></div>
          <span class="side-menu">
            <latam-nav-menu></latam-nav-menu>
          </span>
          <div class="border-line-menu"></div>
          <span class="side-menu">
            <app-language-menu></app-language-menu>
          </span> -->
          <div class="border-line-menu"></div>
          <span *ngIf="!($isLoggedIn | async)" class="side-menu">
            <mat-icon>mail</mat-icon>
            <div class="icon-flex">
              <a class="nav-item" (click)="openEmail()">
                <span class="text-span" translate="navLinks.contact"></span>
              </a>
              <!-- <mat-icon>arrow_drop_down</mat-icon> -->
            </div>
          </span>
          <span
            *ngIf="
              userIsLoggedIn &&
              (isAdmin || (isBR() && isResearch)) &&
              (isMX() || isBR())
            "
            class="side-menu"
          >
            <mat-icon>person</mat-icon>
            <div mat-button [matMenuTriggerFor]="admin" class="icon-flex">
              <a class="nav-item">
                {{ "global.menu.admin.admin" | translate }}
              </a>
              <mat-icon>arrow_drop_down</mat-icon>
              <mat-menu #admin="matMenu">
                <a class="btn-decoration">
                  <button
                    (click)="openMenuOption('company')"
                    mat-menu-item
                    class="mat-sub-menu"
                  >
                    {{ "companyMenu" | translate }}
                  </button>
                </a>
                <a class="btn-decoration">
                  <button
                    (click)="openMenuOption('users')"
                    mat-menu-item
                    class="mat-sub-menu"
                  >
                    {{ "registerUser" | translate }}
                  </button>
                </a>
                <a class="btn-decoration">
                  <button
                    (click)="openMenuOption('corporations-with-portfolios')"
                    mat-menu-item
                    class="mat-sub-menu"
                  >
                    {{ "companiesPortfolios" | translate }}
                  </button>
                </a>
                <a class="btn-decoration">
                  <button
                    (click)="openMenuOption('realEstateFund')"
                    mat-menu-item
                    class="mat-sub-menu"
                  >
                    {{ "realEstateFunds" | translate }}
                  </button>
                </a>
                <a class="btn-decoration">
                  <button
                    (click)="openQuarterly()"
                    mat-menu-item
                    class="mat-sub-menu"
                  >
                    {{ "quarterlyStatsBooking" | translate }}
                  </button>
                </a>
              </mat-menu>
            </div>
          </span>

          <div *ngIf="!($isLoggedIn | async)" class="button-nav-menu">
            <div>
              <button
                mat-flat-button
                class="pop login-btn-menu"
                (click)="login()"
              >
                <span
                  class="siila-blue-color uppercase"
                  translate="login"
                ></span>
              </button>
            </div>
            <div>
              <button
                mat-flat-button
                class="pop2 demo-btn-menu"
                (click)="openDemoDialog()"
              >
                <span
                  class="siila-blue-color bold uppercase"
                  translate="demo"
                ></span>
              </button>
            </div>
          </div>
          <div
            style="bottom: 120px;
        position: absolute;
        margin: 0 0 12px 0px;width: 100%;"
            *ngIf="$isLoggedIn | async"
          >
            <div class="border-line-menu"></div>
            <span class="side-menu">
              <mat-icon>business</mat-icon>
              <div class="icon-flex">
                <a class="nav-item" (click)="login()">
                  <span class="text-span"> Market Analytics</span>
                </a>
              </div>
            </span>
          </div>
          <div
            style="bottom: 34px;
        position: absolute;
        margin: 0 0 12px 0px;width: 100%;"
            *ngIf="$isLoggedIn | async"
          >
            <div class="border-line-menu"></div>
            <span class="side-menu" style="color: red;">
              <mat-icon>exit_to_app</mat-icon>
              <div class="icon-flex">
                <a class="nav-item" (click)="logout()">
                  <span class="text-span" style="color: red;">
                    {{ "logout" | translate }}</span
                  >
                </a>
              </div>
            </span>
            <div class="border-line-menu"></div>
          </div>
          <div
            style="bottom: 0px;
          position: absolute;background: #f5f5f5;
    width: 100%;
    height: 59px;
         "
            *ngIf="$isLoggedIn | async"
          >
            <img
              [src]="photoUser"
              alt=""
              style="width: 36px;
          height: 36px;
          border-radius: 3em;
          margin: 14px;"
            />
            <span style="margin-top: 6px;">
              {{ user?.firstName }} {{ user?.lastName }}
            </span>
          </div>
        </mat-list>
      </mat-sidenav>
      <mat-sidenav-content style="min-height:800px;">
        <router-outlet></router-outlet>
        <app-footer *ngIf="!hideMenu"></app-footer>
      </mat-sidenav-content>
      <notifier-container></notifier-container>
    </mat-sidenav-container>
  </div>
</div>
