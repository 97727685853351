import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { tap, map, mapTo, mergeMap } from "rxjs/operators";
import { NGXLogger } from "ngx-logger";
import { PagerService } from "./pager.service";
import { isPlatformServer } from "@angular/common";
import { SiilaTranslationService } from "./siila-translation.service";

@Injectable()
export class NewsService extends PagerService {
  public loading$ = new BehaviorSubject<boolean>(false);
  public currentNewsPage$ = new BehaviorSubject<number>(this.getCurrentPage);

  private currentPage = 1;
  private totalCount: number = 0;
  private pageSize: number = 3;

  public loadedArticles: any = [];

  private host: string = `/storys/displayable/fast?page=1&per_page=`;
  latamCountry = "";

  private changesLangSubject: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public changesLang$ = this.changesLangSubject.asObservable();

  constructor(
    private http: HttpClient,
    private _log: NGXLogger,
    @Inject(PLATFORM_ID) private platformId: any,
    private siilaTranslationService: SiilaTranslationService
  ) {
    super();
    if (isPlatformServer(this.platformId)) {
      this.host = `${environment.serverURL}/api/storys/displayable/fast?page=1&per_page=`;
    }
  }

  get getTotalPageCount(): number {
    return this.totalCount;
  }

  get getCurrentPage(): number {
    return this.currentPage;
  }

  public requestDefaultNews(pageSize?: number): Promise<any> {
    return this.http
      .get(
        (this.latamCountry == "latamMx"
          ? environment.apiURLMX
          : this.latamCountry == "latamBr"
          ? environment.apiURLBR
          : environment.apiURL) +
          this.host +
          (pageSize || this.pageSize),
        {
          observe: "response"
        }
      )
      .pipe(
        tap(
          response =>
            (this.totalCount = parseInt(response.headers.get("x-total-count")))
        ),
        map(val => {
          return {
            headers: val.headers,
            body: val.body
          };
        })
      )
      .toPromise()
      .then(response => {
        const articles = response.body as any[];
        this.appendArticles(articles);

        return response;
      });
  }

  public setTotalCount(totalCountHeader: string) {
    if (!totalCountHeader) {
      return;
    }
    this.totalCount = parseInt(totalCountHeader);
  }

  public queryByPage(page: number, pageSize?: number) {
    return this.http
      .get(
        `${
          this.latamCountry == "latamMx"
            ? environment.apiURLMX
            : this.latamCountry == "latamBr"
            ? environment.apiURLBR
            : environment.apiURL
        }/storys/displayable/fast?page=${page}&per_page=${pageSize ||
          this.pageSize}`
      )
      .toPromise()
      .then((articles: any[]) => {
        this.appendArticles(articles);

        return articles;
      });
  }

  appendArticles(articles: any[]) {
    this.loadedArticles = [...this.loadedArticles, ...articles];
  }

  fetchPage(page) {
    const pagination = this.getPager(this.totalCount, page, this.pageSize);

    return {
      pagination: pagination,
      pageQuery$: async () => {
        return await this.queryByPage(page);
      },
      nextPage: page + 1
    };
  }

  getNewsId(id: number) {
    return this.http.get(
      `${environment.apiURL}/content/news/${id}?countryCode=${environment.platformId}`
    );
  }

  getMostNews() {
    return this.http.get(
      `${environment.apiURL}/content/news/all/most-read?countryCode=${environment.platformId}`
    );
  }

  getNewsTopics() {
    return this.http.get(
      `${environment.apiURL}/content/topics/news?countryCode=${environment.platformId}`
    );
  }

  getNews() {
    return this.http.get(
      `${environment.apiURL}/content/news?countryCode=${environment.platformId}`
    );
  }

  getNewsSearch(
    lang: string,
    page: number,
    perPage: number,
    keyword: string,
    tag = null,
    regionOrigin?: number,
    buildingId = null,
    topic = false
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Cache-Control": "no-cache" // add this header to disable caching
      })
    };

    const params: any = {
      keywordFilter: keyword,
      regionOrigin: regionOrigin,
      regionId: 0,
      includeSections: false,
      includeContentTags: true,
      includeAuthors: false,
      statusFilter: ["PUBLISHED"],
      buildingId: buildingId
    };

    if (topic) {
      params.topicId = tag;
    } else {
      params.tagIdFilter = tag;
    }

    return this.http.post(
      `${environment.apiURL}/content/news/search/${
        environment.platformId == "co" || environment.platformId == "latam"
          ? lang
          : environment.platformId
      }/${
        environment.platformId == "co"
          ? "en"
          : this.siilaTranslationService.getCurrentLanguage()
      }?page=${page}&per_page=${perPage}`,
      params,
      httpOptions
    );
  }

  notifiChnagesLang() {
    this.changesLangSubject.next(true);
  }

  getSubscription(id) {
    return this.http.get(
      `${environment.apiURL}/company-data-setup/company/${id}`
    );
  }
}
