<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-3 f-left mt-5">
      <div>
        {{ "transaction.transactions" | translate }}
        {{
          transaction?.type == "SALE"
            ? ("transaction.sale" | translate)
            : ("transaction.lease" | translate)
        }}
      </div>

      <div>
        {{ transaction?.building?.buildingType?.name | langSelector }}
      </div>
    </div>

    <div class="col-md-6 title-row  f-left mt-1">
      <span class="title">
        {{ transaction?.building?.title | langSelector }}
      </span>
      <br />
      <span>
        {{ transaction?.building?.address | langSelector }},
        {{ transaction?.building?.city?.name | langSelector }},
        {{
          abbreviatedText(
            transaction?.building?.city?.countryState?.name | langSelector
          )
        }}
      </span>
    </div>
    <div class="col-md-3 f-left mt-5" style="text-align: end;">
      {{
        platformId == "mx"
          ? transaction?.building.subMarket?.name
          : (transaction?.building.region?.name | langSelector)
      }}
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <div class="w-100">
      <app-show-images
        [photos]="photos"
        [latitude]="transaction?.building?.latitude"
        [longitude]="transaction?.building?.longitude"
        [buildingTypeId]="transaction?.building?.buildingType.id"
        class="d-flex pl"
      ></app-show-images>
    </div>
    <div class="col-md-12 content-option mt-3" [ngClass]="{ 'p-0': isMobile }">
      <div class="content-option-btn col-md-8">
        <div *ngIf="transaction?.building?.buildingType.id != 9001">
          <img
            style="width: 20px;
          margin-right: 5px;"
            src="assets/featuresIcons/generate-reports.png"
            alt=""
          />
          <span (click)="downloadReport()">{{
            "detail.generateReport" | translate
          }}</span>
        </div>
        <div (click)="isAdmin ? edit() : suggestEdit()">
          <mat-icon style="margin-right: 6px;">edit</mat-icon>
          <span>{{
            isAdmin
              ? ("detail.edit" | translate)
              : ("detail.suggestEdit" | translate)
          }}</span>
        </div>
        <div>
          <img
            style="width: 20px;
        margin-right: 5px;"
            src="assets/featuresIcons/analyze-2.png"
            alt=""
          /><span (click)="transactionsPage()">{{
            "transaction.viewTransactions" | translate
          }}</span>
        </div>
      </div>
      <div class="content-option-btn col-md-4">
        <div
          style="border: none;
            border-right: 1px solid gray;
            border-radius: inherit;margin-left: -40px;"
        ></div>
        <div *ngIf="registry.length > 0">
          <span (click)="openPhotos(2)">{{
            "detail.matricula" | translate
          }}</span>
        </div>
        <div *ngIf="plan.length > 0">
          <span (click)="openPhotos(3)">{{ "detail.plans" | translate }}</span>
        </div>
        <div *ngIf="documents.length > 0">
          <span (click)="openPhotos(4)">{{
            "detail.otherDocs" | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-2" [ngClass]="{ 'p-0': isMobile }">
      <div style="border-bottom: 1px solid #00334d;margin: 22px;"></div>
    </div>
    <div class="col-md-12 mt-5 content-columns">
      <div class="col-md-8" [ngClass]="{ 'p-0': isMobile }">
        <div
          class="col-md-12 p-r"
          *ngIf="
            transaction?.salesNotes &&
            validateTrnaslate(transaction?.salesNotes) != ''
          "
        >
          <div class="col-md-12 key-facts ">
            <span>{{ "transaction.saleDescription" | translate }}</span>
          </div>

          <div
            class="mt-2"
            style="margin: 0;text-align: justify;white-space: pre-line;"
          >
            <span class="mt-2">{{
              transaction?.salesNotes | langSelector
            }}</span>
          </div>
        </div>
        <div
          class="col-md-12 p-r"
          *ngIf="
            transaction?.leaseNotes &&
            validateTrnaslate(transaction?.leaseNotes) != ''
          "
        >
          <div class="col-md-12 key-facts ">
            <span>{{ "transaction.leaseDescription" | translate }}</span>
          </div>

          <div class="mt-2" style="margin: 0;text-align: justify;">
            <span class="mt-2">{{
              transaction?.leaseNotes | langSelector
            }}</span>
          </div>
        </div>
        <div class="key-columns">
          <div class="col-md-6">
            <div class="col-md-12 key-facts mt-2">
              <span
                >{{
                  browserLang === "en"
                    ? transaction?.type == "SALE"
                      ? ("transaction.sale" | translate)
                      : ("transaction.lease" | translate)
                    : ""
                }}
                {{ "detail.keyFacts" | translate }}</span
              >
            </div>
            <div class="d-flex key-facts-columns border-table">
              <div class="column label-color">
                {{ "transaction.property" | translate }}
              </div>
              <div class="column label-color2">
                <a class="link" (click)="goToProperty()">{{
                  property?.title | langSelector
                }}</a>
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.building?.classType?.name"
              >
                {{ "transaction.class" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.building?.classType?.name"
              >
                {{ transaction?.building?.classType?.name }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  platformId == 'mx' && transaction?.building?.subMarket?.name
                "
              >
                {{ "detail.subMarket" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  platformId == 'mx' && transaction?.building?.subMarket?.name
                "
              >
                {{ transaction?.building?.subMarket?.name }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.building?.region?.name"
              >
                {{ "detail.region" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.building?.region?.name"
              >
                {{ transaction?.building?.region?.name | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  transaction?.buyers && (transaction?.buyers | langSelector)
                "
              >
                {{ "transaction.buyer" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  transaction?.buyers && (transaction?.buyers | langSelector)
                "
              >
                {{ transaction?.buyers | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  transaction?.sellers && (transaction?.sellers | langSelector)
                "
              >
                {{ "transaction.seller" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  transaction?.sellers && (transaction?.sellers | langSelector)
                "
              >
                {{ transaction?.sellers | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  transaction?.tenants &&
                  (transaction?.tenants | langSelector) &&
                  transaction?.type != 'SALE'
                "
              >
                {{ "transaction.tenant" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  transaction?.tenants &&
                  (transaction?.tenants | langSelector) &&
                  transaction?.type != 'SALE'
                "
              >
                {{ transaction?.tenants | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  transaction?.owner &&
                  (transaction?.owner | langSelector) &&
                  transaction?.type != 'SALE'
                "
              >
                {{ "transaction.owner" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  transaction?.owner &&
                  (transaction?.owner | langSelector) &&
                  transaction?.type != 'SALE'
                "
              >
                {{ transaction?.owner | langSelector }}
              </div>
              <div class="column label-color" *ngIf="transaction?.floor">
                {{ "transaction.unit" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.floor">
                {{ transaction?.floor }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.leaseDate && transaction?.type == 'LEASE'"
              >
                {{ "transaction.leaseDate" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.leaseDate && transaction?.type == 'LEASE'"
              >
                {{
                  platformId == "mx"
                    ? formatShortDates(transaction?.leaseDate)
                    : (transaction?.leaseDate
                      | date
                        : (browserLang === "pt" ? "dd/MM/yyyy" : "MM/dd/yyyy"))
                }}
              </div>
              <div class="column label-color" *ngIf="transaction?.monthlyRent">
                {{
                  platformId == "mx"
                    ? ("transaction.monthlyRentMxn" | translate)
                    : ("transaction.monthlyRent" | translate)
                }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.monthlyRent">
                {{ transaction?.monthlyRent | localNumber: "1.0-0" }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.saleDate && transaction?.type == 'SALE'"
              >
                {{ "transaction.saleDate" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.saleDate && transaction?.type == 'SALE'"
              >
                {{
                  platformId == "mx"
                    ? formatShortDates(transaction?.saleDate)
                    : (transaction?.saleDate
                      | date
                        : (browserLang === "pt" ? "dd/MM/yyyy" : "MM/dd/yyyy"))
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="platformId != 'mx' && transaction?.salePrice"
              >
                {{ "transaction.totalPrice" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId != 'mx' && transaction?.salePrice"
              >
                {{ transaction?.salePrice | localNumber: "1.0-0" }}
              </div>
              <div
                class="column label-color"
                *ngIf="platformId != 'mx' && transaction?.percentInterestSold"
              >
                {{ "transaction.percentInterestSold" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId != 'mx' && transaction?.percentInterestSold"
              >
                {{ transaction?.percentInterestSold | localNumber }}%
              </div>

              <div
                *ngIf="
                  platformId != 'mx' &&
                  (transaction?.saleAndLeaseback ||
                    transaction?.saleAndLeaseback == false)
                "
                class="column label-color"
              >
                {{ "transaction.saleAndLeaseback" | translate }}
              </div>
              <div
                *ngIf="
                  platformId != 'mx' &&
                  (transaction?.saleAndLeaseback ||
                    transaction?.saleAndLeaseback == false)
                "
                class="column label-color2"
              >
                {{
                  transaction?.saleAndLeaseback
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  platformId != 'mx' &&
                  (transaction?.bts || transaction?.bts == false)
                "
                class="column label-color"
              >
                {{ "transaction.bts" | translate }}
              </div>
              <div
                *ngIf="
                  platformId != 'mx' &&
                  (transaction?.bts || transaction?.bts == false)
                "
                class="column label-color2"
              >
                {{
                  transaction?.bts
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <!--  <div class="column label-color" *ngIf="transaction?.floorLoad">
                {{ "transaction.floorLoad" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.floorLoad">
                {{ transaction?.floorLoad }}
              </div> -->
              <div class="column label-color" *ngIf="transaction?.leaseTerm">
                {{ "transaction.leaseTerm" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.leaseTerm">
                {{ transaction?.leaseTerm }}
              </div>

              <div
                class="column label-color"
                *ngIf="transaction?.maturityDateContract"
              >
                {{ "transaction.leaseMaturityDate" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.maturityDateContract"
              >
                {{
                  platformId == "mx"
                    ? formatShortDates(transaction?.maturityDateContract)
                    : (transaction?.maturityDateContract
                      | date
                        : (browserLang === "pt" ? "dd/MM/yyyy" : "MM/dd/yyyy"))
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="platformId != 'mx' && transaction?.freeRentMonths"
              >
                {{ "transaction.freeRentMonths" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId != 'mx' && transaction?.freeRentMonths"
              >
                {{ transaction?.freeRentMonths }}
              </div>

              <div
                class="column label-color"
                *ngIf="
                  platformId != 'mx' &&
                  transaction?.escalations &&
                  (transaction?.escalations | langSelector)
                "
              >
                {{ "transaction.formattedEsc" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  platformId != 'mx' &&
                  transaction?.escalations &&
                  (transaction?.escalations | langSelector)
                "
              >
                {{ transaction?.escalations | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  transaction?.expenseReimbursementTerms &&
                  transaction?.type != 'LEASE' &&
                  transaction?.type != 'SALE'
                "
              >
                {{ "transaction.expenseReimbursementTerms" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  transaction?.expenseReimbursementTerms &&
                  transaction?.type != 'LEASE' &&
                  transaction?.type != 'SALE'
                "
              >
                {{ transaction?.expenseReimbursementTerms | langSelector }}
              </div>

              <div
                class="column label-color"
                *ngIf="transaction?.occupancyAtLease"
              >
                {{ "transaction.occupancyAtLease" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.occupancyAtLease"
              >
                {{ transaction?.occupancyAtLease | langSelector }}%
              </div>

              <div
                class="column label-color"
                *ngIf="platformId == 'mx' && transaction?.originalCurrency"
              >
                {{ "transaction.transactionCurrency" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId == 'mx' && transaction?.originalCurrency"
              >
                {{ transaction?.originalCurrency }}
              </div>
              <div
                class="column label-color"
                *ngIf="platformId == 'mx' && transaction?.salePrice"
              >
                {{ "transaction.salePriceMxn" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId == 'mx' && transaction?.salePrice"
              >
                {{ transaction?.salePrice | localNumber: "1.2-2" }}
              </div>
              <div
                class="column label-color"
                *ngIf="platformId == 'mx' && transaction?.exchangeRate"
              >
                {{ "transaction.exchangeRate" | translate }}

                <img
                  style="width: 17px;"
                  src="assets/featuresIcons/circle-info-solid.svg"
                  matTooltip="{{ 'transaction.exchangeTootip' | translate }}"
                  matTooltipPosition="above"
                />
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId == 'mx' && transaction?.exchangeRate"
              >
                {{ transaction?.exchangeRate | localNumber: "1.2-2" }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  platformId == 'mx' &&
                  transaction?.type == 'LEASE' &&
                  transaction?.occupancyRate
                "
              >
                {{ "transaction.occupancyAtLeaseRate" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  platformId == 'mx' &&
                  transaction?.type == 'LEASE' &&
                  transaction?.occupancyRate
                "
              >
                {{ transaction?.occupancyRate | localNumber: "1.0-0" }}%
              </div>
              <div
                class="column label-color"
                *ngIf="platformId == 'mx' && transaction?.percentInterestSold"
              >
                {{ "transaction.percentInterestSold" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId == 'mx' && transaction?.percentInterestSold"
              >
                {{ transaction?.percentInterestSold }}%
              </div>
              <!--  <div
                class="column label-color"
                *ngIf="
                  platformId == 'mx' &&
                  (transaction?.isImperialMeasure == false ||
                    transaction?.isImperialMeasure == null ||
                    transaction?.isImperialMeasure)
                "
              >
                {{ "transaction.transactionMeasurement" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  platformId == 'mx' &&
                  (transaction?.isImperialMeasure ||
                    transaction?.isImperialMeasure == null ||
                    transaction?.isImperialMeasure == false)
                "
              >
                {{
                  transaction?.isImperialMeasure
                    ? "Imperial"
                    : ("transaction.metric" | translate)
                }}
              </div> -->
              <div
                class="column label-color"
                *ngIf="platformId == 'mx' && transaction?.occupancyAtSale"
              >
                {{ "transaction.occupancyAtTransactionSale" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="platformId == 'mx' && transaction?.occupancyAtSale"
              >
                {{ transaction?.occupancyAtSale }}%
              </div>
              <div
                class="column label-color"
                *ngIf="
                  platformId == 'mx' && transaction?.corporationPortfolio?.name
                "
              >
                {{ "transaction.corporationPortfolio" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  platformId == 'mx' && transaction?.corporationPortfolio?.name
                "
              >
                {{ transaction?.corporationPortfolio?.name }}
              </div>
              <div class="column label-color" *ngIf="transaction?.status">
                {{ "transaction.recordStatus" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.status">
                {{
                  platformId == "mx" && browserLang == "es"
                    ? translateStatus(transaction?.status)
                    : transaction?.status
                }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12 key-facts mt-2">
              <span>{{ "detail.areasMeasurements" | translate }}</span>
            </div>

            <app-areas-measurements-trx-br
              *ngIf="platformId == 'br'"
              [validateBomaList]="validateBomaList"
              [transaction]="transaction"
            ></app-areas-measurements-trx-br>
            <app-areas-measurements-trx-mx
              *ngIf="platformId == 'mx'"
              [transaction]="transaction"
            ></app-areas-measurements-trx-mx>

            <div
              *ngIf="transaction?.type != 'LEASE' && platformId == 'br'"
              class="col-md-12 key-facts mt-2"
            >
              <span>{{ "transaction.capRateAnalysis" | translate }}</span>
            </div>
            <div
              *ngIf="transaction?.type != 'LEASE' && platformId == 'br'"
              class="d-flex key-facts-columns border-table"
            >
              <div class="column label-color" *ngIf="transaction?.yearCapRate">
                {{ "transaction.capRateYear" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.yearCapRate">
                {{ transaction?.yearCapRate | localNumber }} %
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.brazilianCapRate"
              >
                {{ "transaction.capRateBrazil" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.brazilianCapRate"
              >
                {{ transaction?.brazilianCapRate | localNumber }} %
              </div>
              <div class="column label-color" *ngIf="transaction?.capRate">
                {{ "transaction.capRateStbilized" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.capRate">
                {{ transaction?.capRate | localNumber }} %
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2 p-0">
          <div *ngIf="validateBrokerList" class="col-md-12 key-facts mt-2">
            <span>{{ "transaction.brokerDetail" | translate }}</span>
          </div>
          <div class="broker" *ngIf="validateBrokerList">
            <div style="margin-right: 10px;">
              <table class="w-100">
                <tr
                  *ngIf="
                    transaction?.broker2User ||
                    transaction?.broker2CompanyName ||
                    transaction?.broker2Company
                  "
                >
                  <td class="label-color">
                    {{
                      transaction?.type == "LEASE"
                        ? ("transaction.tenantFirm" | translate)
                        : ("transaction.sellerFirm" | translate)
                    }}
                  </td>
                  <td class="label-color2 td-2">
                    {{
                      transaction?.broker2CompanyName
                        ? transaction?.broker2CompanyName
                        : transaction?.broker2Company
                        ? transaction?.broker2Company
                        : transaction?.broker2User.company.name
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="transaction?.broker2User || transaction?.broker2Name"
                >
                  <td class="label-color">
                    {{
                      transaction?.type == "LEASE"
                        ? ("transaction.tenantName" | translate)
                        : ("transaction.sellerName" | translate)
                    }}
                  </td>
                  <td class="label-color2 td-2">
                    {{
                      transaction?.broker2Name
                        ? transaction?.broker2Name
                        : transaction?.broker2User.firstNameAndLastName
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="transaction?.broker2User || transaction?.broker2Phone"
                >
                  <td class="label-color">
                    {{
                      transaction?.type == "LEASE"
                        ? ("transaction.tenantPhone" | translate)
                        : ("transaction.sellerPhone" | translate)
                    }}
                  </td>
                  <td class="label-color2 td-2" style="color: #2c94d3">
                    <img
                      class="show-data-img"
                      src="assets/featuresIcons/call_icon.png"
                      alt=""
                    />
                    <span
                      *ngIf="!showPhone1"
                      (click)="showPhone1 = !showPhone1"
                      style="cursor: pointer;"
                    >
                      {{ "detail.showPhone" | translate }}
                    </span>
                    <span *ngIf="showPhone1">
                      {{
                        transaction?.broker2Phone
                          ? transaction?.broker2Phone
                          : transaction?.broker2User.company.primaryPhone
                      }}
                    </span>
                  </td>
                </tr>
                <tr
                  *ngIf="transaction?.broker2User || transaction?.broker2Email"
                >
                  <td class="label-color">
                    {{
                      transaction?.type == "LEASE"
                        ? ("transaction.tenantEmail" | translate)
                        : ("transaction.sellerEmail" | translate)
                    }}
                  </td>
                  <td class="label-color2 td-2" style="color: #2c94d3">
                    <img
                      class="show-data-img"
                      src="assets/featuresIcons/mail_icon.png"
                      alt=""
                    />
                    <span
                      *ngIf="!showEmail1"
                      (click)="showEmail1 = !showEmail1"
                      style="cursor: pointer;"
                    >
                      {{ "detail.showEmail" | translate }}
                    </span>
                    <span *ngIf="showEmail1">
                      {{
                        transaction?.broker2Email
                          ? transaction?.broker2Email
                          : transaction?.broker2User.email
                      }}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <table class="w-100">
                <tr
                  *ngIf="
                    transaction?.broker1CompanyName ||
                    transaction?.broker1User ||
                    transaction?.broker1Company
                  "
                >
                  <td class="label-color">
                    {{
                      transaction?.type == "LEASE"
                        ? ("transaction.landlordFirm" | translate)
                        : ("transaction.buyerFirm" | translate)
                    }}
                  </td>
                  <td class="label-color2 td-2">
                    {{
                      transaction?.broker1CompanyName
                        ? transaction?.broker1CompanyName
                        : transaction?.broker1Company
                        ? transaction?.broker1Company
                        : transaction?.broker1User?.company.name
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="transaction?.broker1User || transaction?.broker1Name"
                >
                  <td class="label-color">
                    {{
                      transaction?.type == "LEASE"
                        ? ("transaction.landlordName" | translate)
                        : ("transaction.buyerName" | translate)
                    }}
                  </td>
                  <td class="label-color2 td-2">
                    {{
                      transaction?.broker1Name
                        ? transaction?.broker1Name
                        : transaction?.broker1User?.firstNameAndLastName
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="transaction?.broker1User || transaction?.broker1Phone"
                >
                  <td class="label-color">
                    {{
                      transaction?.type == "LEASE"
                        ? ("transaction.landlordPhone" | translate)
                        : ("transaction.buyerPhone" | translate)
                    }}
                  </td>
                  <td class="label-color2 td-2" style="color: #2c94d3">
                    <img
                      class="show-data-img"
                      src="assets/featuresIcons/call_icon.png"
                      alt=""
                    />
                    <span
                      *ngIf="!showPhone2"
                      (click)="showPhone2 = !showPhone2"
                      style="cursor: pointer;"
                    >
                      {{ "detail.showPhone" | translate }}
                    </span>
                    <span *ngIf="showPhone2">
                      {{
                        transaction?.broker1Phone
                          ? transaction?.broker1Phone
                          : transaction?.broker1User?.company.primaryPhone
                      }}
                    </span>
                  </td>
                </tr>
                <tr
                  *ngIf="transaction?.broker1User || transaction?.broker1Email"
                >
                  <td class="label-color">
                    {{
                      transaction?.type == "LEASE"
                        ? ("transaction.landlordEmail" | translate)
                        : ("transaction.buyerEmail" | translate)
                    }}
                  </td>
                  <td class="label-color2 td-2" style="color: #2c94d3">
                    <img
                      class="show-data-img"
                      src="assets/featuresIcons/mail_icon.png"
                      alt=""
                    />
                    <span
                      *ngIf="!showEmail2"
                      (click)="showEmail2 = !showEmail2"
                      style="cursor: pointer;"
                    >
                      {{ "detail.showEmail" | translate }}
                    </span>
                    <span *ngIf="showEmail2">
                      {{
                        transaction?.broker1Email
                          ? transaction?.broker1Email
                          : transaction?.broker1User.email
                      }}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- Transactions Table -->
        <div class="col-md-12 mt-3 p-0">
          <app-transactions
            [buildingTitle]="property.title"
            [buildingId]="transaction.building.id"
            [transactionId]="transaction.id"
            [txnType]="transaction.type"
          >
          </app-transactions>
        </div>
        <!-- Transactions Table -->
      </div>
      <div *ngIf="!isMobile" class="col-md-4">
        <aside>
          <div *ngIf="listingCards.length > 0">
            <ngx-slick-carousel #slickListing>
              <div ngxSlickItem *ngFor="let listing of listingCards">
                <featured-listing-card
                  class="slide"
                  [hideLogo]="true"
                  [listing]="listing"
                  [latamCountry]="null"
                ></featured-listing-card>
              </div>
            </ngx-slick-carousel>
            <button
              *ngIf="listingCards.length > 1"
              (click)="slickPrev(slickListing)"
              mat-basic-button
              #videoPrev
              aria-label="left arrow"
              class="news-leftLs btnCard chevron_left-listing"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
              *ngIf="listingCards.length > 1"
              (click)="slickNext(slickListing)"
              mat-basic-button
              #videoNext
              aria-label="right arrow"
              class="news-rightLs btnCard chevron_right-listing"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div
            id="findBannerImage"
            (click)="openBannerImage()"
            *ngIf="listingCards.length == 0"
          >
            <img class="banner-img" [src]="findBannerImage()" alt="" />
          </div>
          <div *ngIf="articles && articles.content.length > 0">
            <ngx-slick-carousel #slickResource>
              <div
                ngxSlickItem
                *ngFor="let article of articles.content"
                class="slide"
              >
                <div style="text-align: center;">
                  <span class="title" style="text-decoration: underline;">{{
                    "detail.featuredResource" | translate
                  }}</span>
                </div>
                <div
                  class="mat-elevation-z8 mt-2 resource"
                  style="cursor: pointer;"
                  (click)="openNews(article.id, article)"
                >
                  <img
                    class="w-100 img-topic aspect-4-3"
                    alt="test"
                    title="test"
                    [src]="
                      article?.headerImage?.fullPath
                        ? headerImageNews(article?.headerImage?.fullPath)
                        : ''
                    "
                    default="assets/img/defaultImage.png"
                  />
                  <div class="m-2 mt-3 pb-2">
                    <span style="font-weight: 700; color: #515050;"></span>
                    {{ article.headline | langSelector }}
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
            <button
              *ngIf="articles.content.length > 1"
              [ngClass]="{
                'chevron_left-resource-banner': listingCards.length == 0
              }"
              (click)="slickPrev(slickResource)"
              mat-basic-button
              #videoPrev
              aria-label="left arrow"
              class="news-leftLs btnCard chevron_left-resource"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
              *ngIf="articles.content.length > 1"
              [ngClass]="{
                'chevron_right-resource-banner': listingCards.length == 0
              }"
              (click)="slickNext(slickResource)"
              mat-basic-button
              #videoNext
              aria-label="right arrow"
              class="news-rightLs btnCard chevron_right-resource"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div
            id="findSpotResourceBanner"
            (click)="openSpotResourceBannerLink()"
            *ngIf="articles && articles.content.length == 0"
          >
            <img class="banner-img" [src]="findSpotResourceBanner()" alt="" />
          </div>
        </aside>
      </div>
    </div>

    <div class="col-md-12 mt-5" *ngIf="!hideTrxAnalysis && !isMobile">
      <div class="col-md-12">
        <span class="title col-md-12 "
          >{{ "detail.transactionsAnalytics" | translate }}
          {{
            transaction?.type == "SALE"
              ? (transaction?.building?.city?.countryState?.name
                  | langSelector) +
                (transaction?.building?.classType?.name
                  ? " (" +
                    ("detail.state" | translate) +
                    ") " +
                    getTabClassLabel(
                      transaction?.building?.classType?.name,
                      true
                    )
                  : "")
              : ""
          }}</span
        >
      </div>
      <div class="col-md-8" style="margin: 30 auto;">
        <mat-button-toggle-group
          class="toggle-group w-100 toggle-options"
          appearance="legacy"
          name="fontStyle"
          aria-label="Font Style"
        >
          <mat-button-toggle
            [ngClass]="{ 'active-toggle': typeChar === 1 }"
            (click)="
              transaction?.type == 'SALE' ? setCharSale(1) : setCharLease(1)
            "
            class="toogle-btn"
          >
            {{
              transaction?.type == "SALE"
                ? platformId == "mx"
                  ? ("transaction.salePriceUsdM2" | translate)
                  : ("transaction.salePriceRm" | translate)
                : ("detail.currentProperty" | translate)
            }}</mat-button-toggle
          >
          <mat-button-toggle
            [ngClass]="{ 'active-toggle': typeChar === 2 }"
            (click)="
              transaction?.type == 'SALE' ? setCharSale(2) : setCharLease(2)
            "
            class="toogle-btn"
          >
            {{
              transaction?.type == "SALE"
                ? ("transaction.capRate" | translate) + " (%)"
                : (platformId == "mx"
                    ? transaction?.building?.subMarket.name
                    : (transaction?.building?.region?.name | langSelector)) +
                  " " +
                  getTabClassLabel(transaction?.building?.classType?.name)
            }}</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="col-md-12 mt-2 apex-width" #chart>
        <apx-chart
          *ngIf="
            transaction?.type == 'SALE' && !hideTrxAnalysis && chartOptions
          "
          id="apex-chart"
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [tooltip]="chartOptions.tooltip"
          [colors]="chartOptions.colors"
          [fill]="chartOptions.fill"
        ></apx-chart>
        <apx-chart
          *ngIf="transaction?.type == 'LEASE' && !hideTrxAnalysis"
          [series]="chartOptionsLease.series"
          [chart]="chartOptionsLease.chart"
          [yaxis]="chartOptionsLease.xaxis"
          [yaxis]="chartOptionsLease.yaxis"
          [tooltip]="chartOptionsLease.tooltip"
          [colors]="chartOptionsLease.colors"
        ></apx-chart>
      </div>
    </div>
    <div *ngIf="isMobile" class="col-md-4">
      <aside>
        <div *ngIf="listingCards.length > 0">
          <ngx-slick-carousel #slickListing>
            <div ngxSlickItem *ngFor="let listing of listingCards">
              <featured-listing-card
                class="slide"
                [hideLogo]="true"
                [listing]="listing"
                [latamCountry]="null"
              ></featured-listing-card>
            </div>
          </ngx-slick-carousel>
          <button
            *ngIf="listingCards.length > 1"
            (click)="slickPrev(slickListing)"
            mat-basic-button
            #videoPrev
            aria-label="left arrow"
            class="news-leftLs btnCard chevron_left-listing"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            *ngIf="listingCards.length > 1"
            (click)="slickNext(slickListing)"
            mat-basic-button
            #videoNext
            aria-label="right arrow"
            class="news-rightLs btnCard chevron_right-listing"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <div
          id="findBannerImage"
          (click)="openBannerImage()"
          *ngIf="listingCards.length == 0"
        >
          <img class="banner-img" [src]="findBannerImage()" alt="" />
        </div>
        <div *ngIf="articles && articles.content.length > 0">
          <ngx-slick-carousel #slickResource>
            <div
              ngxSlickItem
              *ngFor="let article of articles.content"
              class="slide"
            >
              <div style="text-align: center;">
                <span class="title" style="text-decoration: underline;">{{
                  "detail.featuredResource" | translate
                }}</span>
              </div>
              <div
                class="mat-elevation-z8 mt-2 resource"
                style="cursor: pointer;"
                (click)="openNews(article.id, article)"
              >
                <img
                  class="w-100 img-topic aspect-4-3"
                  alt="test"
                  title="test"
                  [src]="
                    article?.headerImage?.fullPath
                      ? headerImageNews(article?.headerImage?.fullPath)
                      : ''
                  "
                  default="assets/img/defaultImage.png"
                />
                <div class="m-2 mt-3 pb-2">
                  <span style="font-weight: 700; color: #515050;"></span>
                  {{ article.headline | langSelector }}
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
          <button
            *ngIf="articles.content.length > 1"
            [ngClass]="{
              'chevron_left-resource-banner': listingCards.length == 0
            }"
            (click)="slickPrev(slickResource)"
            mat-basic-button
            #videoPrev
            aria-label="left arrow"
            class="news-leftLs btnCard chevron_left-resource"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            *ngIf="articles.content.length > 1"
            [ngClass]="{
              'chevron_right-resource-banner': listingCards.length == 0
            }"
            (click)="slickNext(slickResource)"
            mat-basic-button
            #videoNext
            aria-label="right arrow"
            class="news-rightLs btnCard chevron_right-resource"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <div
          id="findSpotResourceBanner"
          (click)="openSpotResourceBannerLink()"
          *ngIf="articles && articles.content.length == 0"
        >
          <img class="banner-img" [src]="findSpotResourceBanner()" alt="" />
        </div>
      </aside>
    </div>
    <div class="col-md-12 mt-5">
      <p
        translate="products.{{
          currentCode
        }}.products.features-card.marketAnalytics.findMore"
        class="find-more siila-grey-dark"
      ></p>
      <div class="market-analytics-card mt-5">
        <ng-container *ngFor="let card of marketCardList">
          <app-info-card
            (click)="openInfoCard(card)"
            [icon]="card.src"
            [iconAltTitle]="card.iconAltTitle"
            [title]="card.title | langSelector"
            [text]="card.text | langSelector"
            [actionTxt]="card.actionText | langSelector"
            [background]="card.background"
          ></app-info-card>
        </ng-container>
      </div>
    </div>
  </div>
</div>
