<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-12 title-row f-left mt-1">
      <span class="title">
        {{ "tenants-property.marketsStatsConfiguration" | translate }}
      </span>
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <div class="col-md-12 mt-4 ">
      <div class="col-md-12">
        <div
          class="col-md-12 key-facts"
          style="position: relative; display: flex; align-items: center; padding-top: 0px;"
        >
          <span
            style="position: absolute; left: 50%; transform: translateX(-50%); font-weight: 600;"
            >{{ getYearFromFirstTenant() }} {{ getQuarterFromFirstTenant() }}
            {{ "tenants-property.progress" | translate }}</span
          >
          <!--  <span
            style="margin-left: auto; color: cornflowerblue; 
          text-decoration-line: underline; 
          cursor: pointer;"
            (click)="openStatsDashboard()"
          >
            {{ "tenants-property.linkToStatsBookingDashboard" | translate }}
          </span> -->
        </div>
        <div class="table-unitmix">
          <table class="table">
            <thead class="head-vacant title-row unitmixheaders">
              <tr>
                <th style="text-align: left; width: 200px;">
                  {{ "tenants-property.market" | translate }}
                </th>
                <th class="progress-th" style="text-align: left; width: 200px;">
                  {{ "tenants-property.progress" | translate }}
                </th>
                <th style="text-align: left; width: 180px;">
                  {{ "tenants-property.researcher" | translate }}
                </th>
                <th style="text-align: left; width: 180px;">
                  {{ "tenants-property.researchLead" | translate }}
                </th>
                <th style="text-align: left; width: 180px;">
                  {{ "tenants-property.countryManager" | translate }}
                </th>
              </tr>
            </thead>
            <tbody
              class="border-table title-row unitmixtablerows"
              style="text-align: left; font-size: small;"
            >
              <tr *ngFor="let data of marketConfigs; let i = index">
                <td>
                  <span
                    (click)="openStatsPageByMarket(data.marketId)"
                    style="cursor: pointer; text-decoration: underline; color: #007bff;"
                    >{{ data.marketName | langSelector }}</span
                  >
                </td>
                <td>
                  <div style="display: flex;">
                    <div class="progress progress-td" style="width: 85%;">
                      <!-- <div
                        class="progress-bar"
                        role="progressbar"
                        [style.width]="(data.totalSubmitted / data.totalBuildings * 100) + '%'"
                        [attr.aria-valuenow]="data.totalSubmitted"
                        [attr.aria-valuemin]="0"
                        [attr.aria-valuemax]="data.totalBuildings"
                      ></div>
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        [style.width]="(data.totalApproved / data.totalBuildings * 100) + '%'"
                        [attr.aria-valuenow]="data.totalApproved"
                        [attr.aria-valuemin]="0"
                        [attr.aria-valuemax]="data.totalBuildings"
                      ></div>
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        [style.width]="(data.totalRejected / data.totalBuildings * 100) + '%'"
                        [attr.aria-valuenow]="data.totalRejected"
                        [attr.aria-valuemin]="0"
                        [attr.aria-valuemax]="data.totalBuildings"
                      ></div> -->
                      <div
                        class="progress-bar progress-yellow"
                        role="progressbar"
                        [style.width]="
                          (data.totalSubmitted / data.totalBuildings) * 100 +
                          '%'
                        "
                        [attr.aria-valuenow]="data.totalSubmitted"
                        [attr.aria-valuemin]="0"
                        [attr.aria-valuemax]="data.totalBuildings"
                        [matTooltip]="
                          data.totalSubmitted > 0
                            ? data.totalSubmitted + ' assets submitted'
                            : null
                        "
                        matTooltipPosition="above"
                        *ngIf="data.status != 'APPROVED BY MGMT'"
                      ></div>
                      <div
                        class="progress-bar progress-light-green"
                        role="progressbar"
                        [style.width]="
                          (data.totalApproved / data.totalBuildings) * 100 + '%'
                        "
                        [attr.aria-valuenow]="data.totalApproved"
                        [attr.aria-valuemin]="0"
                        [attr.aria-valuemax]="data.totalBuildings"
                        [matTooltip]="
                          data.totalApproved > 0
                            ? data.totalApproved + ' assets approved'
                            : null
                        "
                        matTooltipPosition="above"
                        *ngIf="data.status != 'APPROVED BY MGMT'"
                      ></div>
                      <div
                        class="progress-bar progress-red"
                        role="progressbar"
                        [style.width]="
                          (data.totalRejected / data.totalBuildings) * 100 + '%'
                        "
                        [attr.aria-valuenow]="data.totalRejected"
                        [attr.aria-valuemin]="0"
                        [attr.aria-valuemax]="data.totalBuildings"
                        *ngIf="data.status != 'APPROVED BY MGMT'"
                      ></div>
                      <div
                        class="progress-bar progress-dark-green"
                        role="progressbar"
                        [style.width]="1 * 100 + '%'"
                        [attr.aria-valuenow]="data.totalBuildings"
                        [attr.aria-valuemin]="0"
                        [attr.aria-valuemax]="data.totalBuildings"
                        [matTooltip]="Completed"
                        matTooltipPosition="above"
                        *ngIf="data.status == 'APPROVED BY MGMT'"
                      ></div>
                    </div>
                    <div
                      style="width: 15%; padding-left: 5px; font-weight: 600;"
                    >
                      {{ getProgressBarLegend(data) }}
                      <span style="color: red;">
                        {{ getRejectedLegend(data) }}</span
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <span *ngIf="!isResearchAdmin">{{
                    data.researcherName
                  }}</span>
                  <select
                    [(ngModel)]="data.researcherName"
                    (ngModelChange)="
                      onResearcherSelected($event, data.marketId)
                    "
                    style="width: 100%;"
                    *ngIf="isResearchAdmin"
                  >
                    <option
                      *ngFor="let user of researchUsers"
                      [ngValue]="user.name"
                    >
                      {{ user.name }}
                    </option>
                  </select>
                </td>
                <td>
                  <span *ngIf="!isResearchAdmin">{{
                    data.leadResearcherName
                  }}</span>
                  <select
                    [(ngModel)]="data.leadResearcherName"
                    (ngModelChange)="onLeadSelected($event, data.marketId)"
                    style="width: 100%;"
                    *ngIf="isResearchAdmin"
                  >
                    <option
                      *ngFor="let user of leadResearchUsers"
                      [ngValue]="user.name"
                    >
                      {{ user.name }}
                    </option>
                  </select>
                </td>
                <td>
                  {{ data.countryManagerName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- NgbModal Template -->
<ng-template #chartModal let-modal>
  <div class="custom-modal custom-modal-centered modal-80vh">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ getModalTitle() }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeModal()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="table-tenant-popup" style="height: 300px;">
        <div class="d-flex" *ngIf="currentAction == 'moveIn'">
          <div class="form-check form-switch" style="padding-left: 2.5em;">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              [(ngModel)]="splitSuite"
            />
            <label class="form-check-label" for="flexSwitchCheckDefault">{{
              "tenants-property.splitSuite" | translate
            }}</label>
          </div>
        </div>
        <table class="table">
          <thead
            class="head-vacant"
            style="text-align: center; background-color: #5a86a3;"
          >
            <tr>
              <th style="width: 80px;">
                {{ "tenants-property.floorModule" | translate }}
              </th>
              <th style="max-width: 100px;">
                {{ "tenants-property.suiteWarehouse" | translate }}
              </th>
              <th style="max-width: 100px;">
                {{ "tenants-property.area" | translate }} (m²)
              </th>
              <th style="min-width: 200px;">
                {{ "tenants-property.tenant" | translate }}
              </th>
              <th
                *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                style="max-width: 120px;"
              >
                {{ "tenants-property.industry" | translate }}
              </th>
              <th
                *ngIf="currentAction == 'moveOut' || currentAction == 'moveIn'"
                style="max-width: 120px;"
              >
                {{ "tenants-property.date" | translate }}
              </th>
              <th *ngIf="currentAction == 'moveOut'" style="max-width: 120px;">
                {{ "tenants-property.askingRentMXN" | translate }}
              </th>
              <th *ngIf="currentAction == 'moveOut'" style="max-width: 120px;">
                {{ "tenants-property.askingRentUSD" | translate }}
              </th>
            </tr>
          </thead>
          <tbody style="text-align: center;">
            <tr style="height: 50px;">
              <td>
                {{ selectedTenant.floorModule }}
              </td>
              <td>
                {{ selectedTenant.suiteWarehouse }}
              </td>
              <td *ngIf="!splitSuite">
                {{ selectedTenant.area | localNumber: "1.2-2" }}
              </td>
              <td *ngIf="splitSuite">
                <input
                  type="number"
                  [(ngModel)]="newTenantArea"
                  style="width: 120px;"
                  min="0"
                  max="{{ selectedTenant.area }}"
                />
              </td>
              <td>
                <div
                  style="position: relative; text-align: start;"
                  *ngIf="currentAction != 'edit' && currentAction != 'moveIn'"
                >
                  <span> {{ editTenantName }} </span>
                </div>
                <div
                  style="position: relative; text-align: start; width: 100%;"
                  *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                >
                  <span *ngIf="addTenantMode"> {{ editTenantName }} </span>
                  <input
                    *ngIf="!addTenantMode"
                    type="text"
                    [(ngModel)]="editTenantName"
                    (input)="onInputChange($event)"
                    placeholder="Type to search..."
                    [ngClass]="{ loading: isLoading }"
                    style="width: 100%;"
                  />
                  <!-- Loading icon, only shown when isLoading is true -->
                  <span *ngIf="isLoading" class="loading-icon">🔄</span>

                  <!-- Autocomplete dropdown -->
                  <ul
                    *ngIf="autocompleteResults.length && !isLoading"
                    class="autocomplete-dropdown"
                    style="z-index: 1;"
                  >
                    <li
                      *ngFor="let option of autocompleteResults"
                      (click)="onSelectOption(option)"
                    >
                      {{ option.tenantName }}
                    </li>
                  </ul>
                </div>
                <div
                  style="text-align: left; font-size: smaller;"
                  *ngIf="showAddTenants && !addTenantMode"
                >
                  <label
                    (click)="onAddTenant()"
                    style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                  >
                    {{ "tenants-property.addAsNewTenant" | translate }}
                  </label>
                </div>
                <div
                  style="text-align: left; font-size: smaller;"
                  *ngIf="addTenantMode"
                >
                  <label
                    (click)="onBackToSearchTenant()"
                    style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                  >
                    {{ "tenants-property.backToSearch" | translate }}
                  </label>
                </div>
              </td>
              <td *ngIf="currentAction == 'edit' || currentAction == 'moveIn'">
                <span *ngIf="editIndustryName && !addTenantMode">
                  {{ editIndustryName | langSelector }}</span
                >
                <select
                  [(ngModel)]="selectedMarketSegment"
                  style="width: 100%;"
                  *ngIf="addTenantMode"
                >
                  <option
                    *ngFor="let segment of marketSegments"
                    [ngValue]="segment"
                  >
                    {{ segment.name | langSelector }}
                  </option>
                </select>
              </td>
              <td
                *ngIf="currentAction == 'moveOut' || currentAction == 'moveIn'"
              >
                <input
                  type="date"
                  [(ngModel)]="editMoveOutDate"
                  style="width: 120px;"
                />
              </td>
              <td *ngIf="currentAction == 'moveOut'">
                <input
                  type="number"
                  [(ngModel)]="editAskingRentMXN"
                  style="width: 120px;"
                />
              </td>
              <td *ngIf="currentAction == 'moveOut'">
                <input
                  type="number"
                  [(ngModel)]="editAskingRentUSD"
                  style="width: 120px;"
                />
              </td>
            </tr>
            <tr *ngIf="splitSuite" style="height: 50px;">
              <td>
                {{ selectedTenant.floorModule }}
              </td>
              <td>{{ selectedTenant.suiteWarehouse }}-A</td>
              <td>
                {{ selectedTenant.area - newTenantArea | localNumber: "1.2-2" }}
              </td>
              <td>
                <div style="position: relative; text-align: start;">
                  <span> Vacant </span>
                </div>
              </td>
              <td>
                <span></span>
              </td>
              <td>
                <span></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="discardChanges()"
      >
        {{ "tenants-property.discardChanges" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!validateSaveButton()"
        (click)="saveChanges()"
      >
        {{ "tenants-property.save" | translate }}
      </button>
      <button type="button" class="btn btn-secondary" (click)="closeModal()">
        {{ "tenants-property.close" | translate }}
      </button>
    </div>
  </div>
</ng-template>
